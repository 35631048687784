import React from "react";
import ServicesCard from "../../components/ServicesCard";
import homeimprovements from "../../images/Home/homeimprovements.jpg"
// import deliveryservice from "../../images/Home/deliveryservices.jpg"
import propertyleasing from "../../images/Home/propertyleasing.jpg"

function OurServices() {
    return (
        <>
            <section className="our-services text-light p-5 text-center" >
                <div className="container-bg-our-services container">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="our-services-title-container pb-5">
                            <h5 class="our-services-title">
                                Our Bid to Earn Platform: HOMEQUBE’s Marketplace
                            </h5>
                        </div>
                        <div className="our-services-content-container d-flex align-items-center w-100">
                            <ServicesCard 
                                title={"Home Improvement"}
                                content={"Start your home improvement project and get your materials straight from trusted suppliers."}
                                image={homeimprovements}
                                btnname={"Shop Now"}
                                btnlink={"https://shop.homeqube.com/buyer/s?sellerType=Product"}
                            />
                            <ServicesCard 
                                title={"Property Leasing"}
                                content={"Going on a vacation? Don’t miss out on great room deals from our trusted property owners."}
                                image={propertyleasing}
                                btnname={"Book Now"}
                                btnlink={"https://shop.homeqube.com/buyer/s?sellerType=Product"}
                            />
                            {/* <ServicesCard 
                                title={"Delivery Services"}
                                content={"Fast and convenient delivery from point A to point B from our reliable partners."}
                                image={deliveryservice}
                                btnname={"Book Now"}
                                btnlink={"https://shop.homeqube.com/buyer/s?sellerType=Service"}
                            /> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurServices;
