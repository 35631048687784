import React from "react";
import Roadmap from "../modules/homepage-components/Roadmap";
import SED from "../modules/homepage-components/SED";
import ShowCaseHeader from "../modules/homepage-components/ShowCaseHeader";
import NewMission from "../modules/homepage-components/NewMission";
// import Beliefs from "../modules/homepage-components/Beliefs";
// import Context from "../modules/homepage-components/Context";
// import homepageIMG from "../images/NewImages/homepageIMG.svg";

// import TechnologyAgenda from "../modules/homepage-components/TechnologyAgenda";
// import ContextResponsive from "../modules/homepage-components/Context-responsive";
// import Partners from "../modules/homepage-components/Partners";
// import TGEEvents from "../modules/homepage-components/TGE-Events";
import VideoPlayer from "../components/VideoPlayer";
import OurServices from "../modules/homepage-components/OurServices";
import Companies from "../modules/homepage-components/Companies";
import NewGenSection from "../modules/homepage-components/NewGenSection";

function Homepage() {
  return (
    <>
      <ShowCaseHeader />
      <NewMission />
      <OurServices />
      <Companies />
      {/* <Beliefs />
      <section>
        <div className="container img-z-index">
          <img
            src={homepageIMG}
            alt=""
            className="homepage-new-img img-fluid"
          />
        </div>
      </section> */}
      <Roadmap />
      <VideoPlayer />
      <SED />
      {/* <Context /> */}
      {/* <ContextResponsive /> */}
      {/* <TechnologyAgenda /> */}
      {/* <TA /> */}
      {/* <Partners /> */}
      {/* <TGEEvents /> */}
      <NewGenSection />
    </>
  );
}

export default Homepage;
