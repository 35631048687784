import React from "react";
import twitter from "../../images/Icons/twitter.svg";
import { Link } from 'react-router-dom'

function NFTSforHomePartsandDesign() {
  return (
    <>
      <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
          <div class="container p-3">
            <div class="carbon-content-container-bg">
              <div class="d-sm-flex align-items-center justify-content-between">
                <div class="container-sm">
                  <h3 class="carbon-benefits-content">
                    NFTs for home parts and design explained
                  </h3>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/NFTs_for_home_parts_and_design_explained.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img
                        class="blog-icon me-3"
                        src={twitter}
                        alt=""
                      />
                    </a>
                  </p>

                  <p class="carbon-text ">
                    <br />
                    <br />
                    The internet is all abuzz over non-fungible tokens or NFTs,
                    but what benefits and possibilities do these digital assets
                    hold for home parts designers and similar creators?
                    <br />
                    <br />
                    NFTs, which mean non-fungible tokens, are all the rage on
                    the internet today. Think of them as digital files, not
                    unlike graphic designs or architectural renderings when
                    you’re trying to get your house built. Photos, digital art,
                    video, audio -- name it are they are certified with NFTs.
                    <br />
                    <br />
                    These are up for sale on blockchain, where you can mint,
                    buy, or sell them using cryptocurrency. Data recorded on the
                    blockchain validates the ownership as well as originality of
                    NFTs.
                    <br />
                    <br />
                    NFTs <a
                      class="blog-link"
                      href="https://www.nftically.com/blog/curious-world-of-nft-real-estate-and-design/"
                      target="blank"
                    >
                      
                      have made their way into real estate,
                    </a> where you can imagine -- perhaps previously unimaginable --
                    multi-store buildings getting sold on digital auction
                    platforms. The savvy ones spend lots of money on digital
                    lands. In fact, over $55 million in sales have gone to
                    leading virtual NFT real estate sellers, while popular
                    virtual game NFT THe Sandbox has sold over $19 million worth
                    of merchandise.
                    <br />
                    <br />
                    As more concrete examples, Krista Kim designed and sold a
                    virtual reality house for half a million dollars in March
                    2021, while Andrés Reisinger made nearly the same money
                    selling a collection of digital furniture.
                  </p>

                  <h3 class="carbon-benefits-content-head">
                    Benefits of NFT design and art
                  </h3>

                  <p class="carbon-text ">
                    For designers, artists, and creators, NFTs 
                    <a
                      class="blog-link"
                      href="https://myventurepad.com/what-is-nft-and-how-it-will-benefit-designing-and-artist-community/"
                      target="blank"
                    > offer </a> a world of possibilities.
                    <br />
                    <br />
                    
                    <ul class="carbon-text ">
                      <li>
                        <b>Ownership</b> - The concept of your own digital file
                        or artwork is an endlessly enticing one. NFTs enable
                        designers and artists to own the rights of their digital
                        work, which they can sell or display as they wish. As
                        blockchain is fully decentralized, it can record the
                        transactions and surely do so, making it almost
                        impossible to alter the transaction record. This takes
                        care of the ownership part.
                      </li>
                      <li>
                        <b>Income and royalties</b> - You’d be surprised to see
                        how NFTs get sold for millions of dollars at auctions.
                        Designers produce intimate work and earn rewards
                        globally, with no need to reach out to clients and ask
                        for payments, or perhaps what happens all too often:
                        revise their work endlessly.
                      </li>
                      <li>
                        <b>Global reach</b> - There used to be a time when
                        physical art gallery events hosted auctions for
                        passionate art and design collectors. With NFTs
                        transacted on a global scale and across borders,
                        competition for choice pieces increases and creators get
                        higher value than usual.
                      </li>
                      <li>
                        <b>Increased engagement</b> - NFTs foster greater
                        interactions in both online and offline spaces, such as
                        NFT properties like the Mars House explored in augmented
                        or virtual reality. Creators can show off their digital
                        goods in the promising metaverse, which represents an
                        immersive, 3D-rendered online world.
                      </li>
                    </ul>
                  </p>

                  <h3 class="carbon-benefits-content-head">
                    Smart contracting
                  </h3>

                  <p class="carbon-text ">
                    A smart contract certifies the ownership of NFTs. Via the
                    process of mining, smart contracts record NFT transactions
                    on the blockchain, which you can consider a digital ledge
                    that powers the exchange of cryptocurrencies like Bitcoin.
                    <br />
                    <br />
                    While these contracts <a
                      class="blog-link"
                      href="https://www.architecturaldigest.com/story/everything-designers-actually-need-to-know-about-the-weird-wild-world-of-nfts"
                      target="blank"
                    >monitor</a> the terms associated with an NFT, they aren't one size fits
                    all. The creator can dictate the selling price and any extra
                    terms, from royalties to commissions during reselling.
                    <br />
                    <br />
                    This reinforces one of the benefits listed above: NFT
                    designers can enjoy a sustainable source of income over
                    time!
                  </p>

                  <h3 class="carbon-benefits-content-head">
                    NFTs in home parts and design
                  </h3>
                  <p class="carbon-text ">
                    Parts designers, who are involved in building assembly parts
                    of a house, can tap on the same opportunities presented by
                    NFTs to other types of designers and artists. Their talents
                    and skills in crafting furniture, home mechanisms, and
                    innovative kits-of-parts chart a clear path for them in the
                    NFT space.
                    <br />
                    <br />
                    On a decentralized platform like Homeqube, which caters to
                    the homebuilding space, home parts designers can follow this
                    recommended path:
                  </p>

                  <ol class="carbon-text " type="1">
                    <li>Submit your parts design.</li>
                    <li>
                      Wait for Knob integration. (
                        <Link class="blog-link" to='/resources-faqs'>
                        Learn more about Knobs here
                        </Link>
                      )
                    </li>
                    <li>Get matched with manufacturers in the ecosystem.</li>
                    <li>
                      Apply for smart contract royalties for parts sold through
                      NFTs.
                    </li>
                  </ol>
                  <p class="carbon-text ">
                    Make no mistake about it: skepticism around NFTs, however,
                    exists. Challenges include the actual NFT creation process,
                    wrapping the NFT within a legal/regulatory framework, as
                    well as creating a know your customer (KYC) process since
                    most art NFT transactions are anonymous, as Forbes <a
                      class="blog-link"
                      target="blank"
                      href="https://www.forbes.com/sites/nataliakarayaneva/2021/11/24/real-estate-nfts-how-it-began/?sh=2cdcadbb3b12"
                    > pointed out. </a>
                    <br />
                    <br />
                    Yet for designers and artists who are not digitally native,
                    NFTs present a unique opportunity once they get (and start
                    to benefit) from a head start in the space. A digital-first
                    approach, after all, reimagines not just the creative
                    process but also the compensation aspect of it.
                    <br />
                    <br />
                    <i>
                      Keen to know more about the freedom afforded by NFTs to
                      graphic artists, generative designers, and similar
                      creators to sell, promote, and protect their intellectual
                      properties? <a
                        class="blog-link"
                        href="/documents/HomeQube Brief 1.0 [with Whitepaper].pdf"
                        target="blank"
                      >Read our whitepaper</a> to learn more.
                    </i>
                    <br />
                    <br />
                    Published on March 2022
                  </p>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/NFTs_for_home_parts_and_design_explained.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img
                        class="blog-icon me-3"
                        src={twitter}
                        alt=""
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NFTSforHomePartsandDesign;
