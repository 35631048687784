import React, { useEffect, useState } from "react";
import { Popover as ReactTinyPopover } from "react-tiny-popover";

const contentContainerStyle = {
    backgroundColor: "white",
    width: "400px",
    borderRadius: "0.5rem",
    padding: "1rem",
    margin: "1rem",
    boxShadow:
        "0px 153px 61px rgba(0, 0, 0, 0.01), 0px 86px 52px rgba(0, 0, 0, 0.03), 0px 38px 38px rgba(0, 0, 0, 0.04), 0px 10px 21px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05)",
};

export default function Popover(props) {
    const [isOpens, setIsOpen] = useState(props.isOpen);

    useEffect(() => {
        if (props.isOpen) { return setIsOpen(true); }

        const timeout = setTimeout(() => {
            setIsOpen(props.isOpen);
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.isOpen]);

    return (
        <ReactTinyPopover
            isOpen={isOpens}
            content={
                <div
                    style={{
                        ...contentContainerStyle,
                        backgroundColor: props.backgroundColor || "white",
                        width: props.width || "400px",
                        borderRadius: props.borderRadius || "0.5rem",
                    }}
                    
                >
                    {props.content}
                </div>
            }
            positions={props.positions || ["bottom", "left", "right", "top"]}
            onClickOutside={props.onClickOutside}
        >
            <span>{props.trigger}</span>
        </ReactTinyPopover>
    );
}
