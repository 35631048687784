import React from "react";
import { Link } from "react-router-dom";
import rightArrowBlack from "../../images/NewImages/Arrow right-black.svg";

function StateofPlayFootbar() {
  return (
    <>
      <section class="text-light new-footbar-bg pb-5">
        <div class="container p-4">
          <div className="d-md-flex justify-content-between align-items-center">
            <h3 className="new-footbar-text text-uppercase">
            Want to learn more about qube tokenomics?
            </h3>
            <div class="footbar-btn pb-2 mt-2">
              <Link to="/qube-tokenomics">
                <button
                  class="new-footbar-button btn btn-lg rounded-pill"
                  type="button"
                >
                 Go to tokenomics{" "}
                  <img src={rightArrowBlack} alt="" className="ms-2" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StateofPlayFootbar;
