import React from "react";
import twitter from "../../images/Icons/twitter.svg";
import robot from "../../images/Blog/robot.jpg";
import blog3 from "../../images/NewImages/newBlog/blog3.svg"

function WhatIsBottomUpApproarch() {
  return (
    <>
      <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
          <div class="container p-3">
            <div class="carbon-content-container-bg">
              <div class="d-sm-flex align-items-center justify-content-between">
                <div class="container-sm">
                  <h3 class="carbon-benefits-content">
                    What is the bottom-up approach in homebuilding?
                  </h3>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/What_is_the_bottom_up_approach_in_homebuilding.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img class="blog-icon me-3" src={twitter} alt="" />
                    </a>
                  </p>

                  <p class="carbon-text ">
                    <br />
                    <br />
                    Home construction has always followed a top-bottom planning
                    approach. It might be high time to consider a bottom-up
                    approach in the search for new efficiencies, greater cost
                    savings, and harnessing of new technologies.
                  </p>

                  <img class="blog-image" src={blog3} alt="" />

                  <p class="carbon-text ">
                    <br />
                    <br />
                    Planning has always been a crucial aspect of a successful,
                    cost-efficient construction project, and it’s generally
                    recommended to have a planning style prior to executing any
                    project, whether it’s a housing or commercial endeavor.
                    <br />
                    <br />
                    Top-down and bottom-up methods are two of the most popular
                    approaches used in home design and building. Generally,
                    top-down planning moves from the general to the specific
                    aspects, while bottom-up planning proceeds from the specific
                    to the general.
                    <br />
                    <br />
                    While the former has always been the more popular approach,
                    bottom-up approaches are rising in appeal and contractors
                    are switching to it for agile planning, fewer interruptions,
                    as well as increased profit.
                  </p>

                  <center>
                    <h3 class="carbon-benefits-content-head">
                      General differences between top-down and bottom-up
                      approaches
                    </h3>
                  </center>

                  <table class="table-responsive-sm table-striped table-borderless table caption-top">
                    <tbody>
                      <tr>
                        <td class="faq-table-content-1">Top-down planning </td>
                        <td class="faq-table-content-1-12">
                          Bottom-up planning
                        </td>
                      </tr>
                      <tr>
                        <td class="faq-table-content-1">
                          Extensive planning and research phase
                        </td>
                        <td class="faq-table-content-1-12">
                          System by system basis
                        </td>
                      </tr>
                      <tr>
                        <td class="faq-table-content-1">
                          Structured control of project
                        </td>
                        <td class="faq-table-content-1-12">
                          More experimentation
                        </td>
                      </tr>
                      <tr>
                        <td class="faq-table-content-1">
                          Main goal → smaller tasks
                        </td>
                        <td class="faq-table-content-1-12">
                          Smaller goals → primary objective
                        </td>
                      </tr>
                      <tr>
                        <td class="faq-table-content-1">General to specific</td>
                        <td class="faq-table-content-1-12">
                          Specific to general
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <p class="carbon-text ">
                    <br />
                    <br />
                    At Homeqube, we have a natural bias toward simplification to
                    reap new efficiencies. With bottom-up planning, one of the
                    greatest advantages is a more realistic plan of the house
                    construction ahead. This realistic approach affects
                    everything from goal setting to budgeting to forecasting.
                    <br />
                    <br />
                    A bottom-up approach helps foster collaboration as everyone
                    is involved in decision-making and provides input into how
                    things are done.
                    <br />
                    <br />
                    Contractors and players in the architecture, engineering,
                    and construction (AEC) space are more motivated, since they
                    play a more active and meaningful role in the entire
                    process. Communication is two-way and there is empowering
                    idea sharing at every level.
                    <br />
                    <br />A bottom-up approach promotes a free flow of ideas and
                    information, where there is likely to be more “out of the
                    box” type thinking in response to the design and building
                    tasks at hand.
                  </p>

                  <img class="blog-image" src={robot} alt="" />

                  <p class="carbon-text ">
                    <br />
                    <br />
                    The majority of built homes were built on top-down
                    processes. With the built environment weighed down by a
                    wealth of inefficiencies, it’s through a more decentralized
                    method, one that incorporates emerging technologies such as
                    blockchain and artificial intelligence, that make new
                    efficiencies, savings, and project benefits possible.
                    <br />
                    <br />
                    At the end of the day, delivering more homes, at a faster
                    pace, matters. Whether it’s top-down planning or the more
                    flexible bottom-up planning at work, what counts the most is
                    delivering on time and at an optimum quality.
                    <br />
                    <br />
                    <i>
                      At Homeqube, we seek to introduce a bottom-up approach as
                      the next legacy building process possible via emerging
                      technologies such as AI and blockchain.{" "}
                      <a
                        class="blog-link"
                        href="/documents/HomeQube Brief 1.0 [with Whitepaper].pdf"
                        target="blank"
                      >
                        Read our whitepaper
                      </a>{" "}
                      to learn more.
                    </i>
                    <br />
                    <br />
                    Published on April 2022
                  </p>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/What_is_the_bottom_up_approach_in_homebuilding.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img class="blog-icon me-3" src={twitter} alt="" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhatIsBottomUpApproarch;
