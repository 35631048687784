import React, { useState, useEffect } from 'react';
import BentoButton from './BentoButton';
import Popover from './TinyPopover';

import shopImg from "../images/sitesnav/sitenav-shop.png"
import nftImg from "../images/sitesnav/sitenav-nft.png"
import buyQubeImg from "../images/sitesnav/sitenav-buyqube.png"
import dappImg from "../images/sitesnav/sitenav-dapp.png"
import daoImg from "../images/sitesnav/sitenav-dao.png"
import communityImg from "../images/sitesnav/sitenav-community.png"
import corpImg from "../images/sitesnav/corporate.png"
import pebImg from "../images/sitesnav/peb.png"
import walletImg from "../images/sitesnav/sitenav-wallet.png"
import cideationImg from "../images/sitesnav/cideation.png"
import howtodocsImg from "../images/sitesnav/howtodocs.png"


function ClickablePopover() {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const imageWidth = 90
    const imageHeight = 90

    const closePopover = () => {
        setIsPopoverOpen(false);
      };
    
      // Add an event listener to close the popover when resizing the screen
      useEffect(() => {
        function handleResize() {
          closePopover();
        }
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <Popover
            isOpen={isPopoverOpen}
            trigger={<BentoButton onClick={() => setIsPopoverOpen(!isPopoverOpen)} />}
            onClickOutside={() => setIsPopoverOpen(false)}
            width={"320px"}
            backgroundColor={"#292c35"}
            borderRadius={"0.8rem"}
            positions={["bottom"]}
            content={
                <>
                    <div className="sitenav-basecard">
                        <div className="sitenav-grid">
                            <a href="https://www.shop.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='Shop'
                                    src={shopImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                    max-width: 100%;
                                    height: auto;
                                `}
                                />
                            </a>
                            <a href="https://www.qube.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='BuyQube'
                                    src={buyQubeImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://www.community.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='Community'
                                    src={communityImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://www.dapp.homeqube.com/home" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='DApp'
                                    src={dappImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://www.dao.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='DAO'
                                    src={daoImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://www.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='Corporate'
                                    src={corpImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://www.peb.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='PEB'
                                    src={pebImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://www.nft.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='NFT'
                                    src={nftImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://www.wallet.homeqube.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='Wallet'
                                    src={walletImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://www.cideation.com/" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='Wallet'
                                    src={cideationImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                            <a href="https://homeqube.gitbook.io/homeqube" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt='How to Docs'
                                    src={howtodocsImg}
                                    width={imageWidth}
                                    height={imageHeight}
                                    css={`
                                    object-fit: contain;
                                `}
                                />
                            </a>
                        </div>
                    </div>
                    
                    
                </>
            } />)
}

export default ClickablePopover