import React, { useState } from "react";
import QubeBotIcon from "./QubeBotIcon";

const AIChatBot = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <div
        title="QUBE AI Chatbot"
        onClick={() => setCollapsed(!collapsed)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          fontSize: "2rem",
          background: "transparent",
          cursor: "pointer",
          borderRadius: "100%",
          color: "white",
          position: "fixed",
          bottom: "1rem",
          right: "0.5rem",
          zIndex: 1002,
          "@media screen and (max-width: 1390px)": {
            bottom: "3.8rem",
          },
          "@media screen and (max-width: 768px)": {
            bottom: "1rem",
          },
        }}
      >
        <QubeBotIcon />
      </div>
      <iframe
        title="QUBE AI Chatbot Iframe"
        style={{
          border: "none",
          position: "fixed",
          borderRadius: "1.5%",
          bottom: "4rem",
          right: "1.5rem",
          background: "black",
          width: "min(390px, 100vw)",
          height: "min(100vh, 600px)",
          display: collapsed ? "block" : "none",
          zIndex: 1000,
          ...(window.innerWidth <= 1390 && {
            bottom: "7rem",
            width: "min(370px, 100vw)",
          }),
          ...(window.innerWidth <= 768 && {
            bottom: "4rem",
            width: "min(320px, 100vw)",
          }),
        }}
        src="https://qubeai-v4-vuszzxdixq-as.a.run.app/"
      ></iframe>
    </>
  );
};

export default AIChatBot;
