import React from 'react'
import twitter from "../../images/Icons/twitter.svg";
import digitalHome from '../../images/Blog/digitalHome.jpg'
import MIT from '../../images/Blog/MIT.PNG'
import prindter from '../../images/Blog/3dprinter.jpg'
import fantastic from '../../images/Blog/Fantastic.jpg'
import blog5 from "../../images/NewImages/newBlog/blog5.svg"

function PrintanEntireHouse() {
  return (
  <>
      <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
            <div class="container p-3">
                <div class="carbon-content-container-bg">
                    <div class="d-sm-flex align-items-center justify-content-between">
                        <div class="container-sm">
                         
                            <h3 class="carbon-benefits-content">
                                Print an Entire House in a Day? The Benefits of Possibilities of 3D Printing in Homebuilding
                           
                            </h3>

                            <h3 class="carbon-benefits-content-head">
                                By Kristopher Ray Pamintuan, PhD
                            </h3>

                            <p class="carbon-text-blog">
                                Share
                                <br/>
                            
                                <a href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/Print_an_Entire_House_in_a_Day.html" target="_blank" rel="noreferrer noopener pt-3" class="flink-icon">
                                    <img class="blog-icon me-3" src={twitter} alt=""/>
                                </a>
                            </p>
                           
                                <br/>
                                <br/>
                        
                            

                                    <img class="blog-image" src={blog5}alt=""/>
                                    <br/>
                                    <br/>
                                    
                                    <p class="carbon-text ">
                                        Additive manufacturing, also commonly known as 3D printing, has rapidly evolved over the last few years to cater to a wide array of applications, from engineering, construction, and manufacturing to food, medical devices, and even tissue engineering. If this is the first time you are learning about 3D printing, it’s interesting to note how it can be key in building your first or next house.
          
                                    </p>

                                    <h3 class="carbon-benefits-content-head">
                                        The Process
                                    </h3>

                                    <br/>
                                    <br/>

                                    <p class="carbon-text ">
                                      Additive manufacturing employs mechanisms for adding a material layer by layer until an object is formed. In its most common form, the material is a thermoplastic filament that is melted by a moving extruder and is deposited layer by layer on a build plate, via a process known as Fused Deposition Modelling (FDM).      
                                        <br/>
                                        <br/>
                                      Think of the filament as the “ink” of the printer that solidifies as soon as it is deposited. This method allows for the printing of any design you can think of, as long as you can create a 3D digital model for it. 
                                    </p>

                                    <a href="http://apt.mit.edu/am-process-comparisons" target="_blank" rel='noreferrer'>
                                    <img class="blog-image" src={MIT} alt=""/>
                                    </a>
                                    <div class="text-start">
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Click on image for more info.<br/>
                                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Source: MIT Center for Additive and Digital Advanced Production Technologies (APT)
                                    </div>

                                    <br/>
                                    <br/>

                                    <p class="carbon-text ">
                                        After the digital modelling, the rest of the work is done by computer algorithms and programs called slicers that optimize the path of the extruder, along with process variables such as printing speed, printing temperature, bed temperature, and infill percent and others to deliver your 3D-printed product. 

                                    </p>

                                    <h3 class="carbon-benefits-content-head">
                                        
                                    Benefits in Home Construction

                                    </h3>
                                   
                                

                                    <p class="carbon-text ">
                                        <br/>
                                        <br/>
                                        With this type of revolutionary technology, it was just a matter of time for us to develop other methods of 3D printing and machines that use other filaments or raw materials as well. Today, you can even have your house printed with concrete mix as the “ink”!
                                        <br/>
                                        <br/>
                                    </p>

                                    <p class="carbon-text ">
                                        3D printing offers several advantages over traditional manufacturing and fabrication methods such as:
                                    </p>

                                    <ul class="carbon-text ">
                                        <li>Reliable repeatability of prints</li>
                                        <li>Enhanced accuracy of printed product</li>
                                        <li>Shorter time between design and production</li>
                                        <li>Less labor, less waste</li>
                                        <li>Customizable designs and rapid prototyping</li>
                                    </ul>

                                  <p class="carbon-text ">
                                    The ability to modify the delivery head of an extrusion-type 3D printer will also allow for flexibility on the material of construction. Delivery heads can even be modified to extrude two or more different materials at the same time for making composites. This capability allows for building objects from materials that cater to specific requirements such as:
                                  </p>

                                  <ul class="carbon-text ">
                                    <li>Weight</li>
                                    <li>Strength</li>
                                    <li>Surface finish</li>
                                    <li>Flexibility</li>
                                    <li>Chemical, thermal, and electrical properties</li>
                                </ul>


                                
                                <img class="blog-image" src={prindter} alt=""/>
                                       
                                <p class="carbon-text ">
                                    <br/>
                                    <br/>
                                    To say that additive manufacturing is forcing the construction and manufacturing industries to evolve is an understatement. 
                                    <br/>
                                    <br/>
                                    In the construction industry, the increasing demand for composite panels as part of prefab houses and buildings can benefit from large scale 3D printing. This allows for easier customization and transport of parts. Curved surfaces and irregular shapes for panels can be easily made in 3D printers with less waste involved.  
                                    <br/>
                                    <br/>
                                    The current technology even allows for the printing of entire houses in a single day! This is done by combining thermosetting polymers with powdered recycled glass and minerals to form a slurry that cures or hardens the moment it is exposed to UV light. This unique property can rival concrete mix in terms of printability, setting time, strength, and sustainability. 
                                    <br/>
                                    <br/>
                                    3D printed houses require considerably less man-hours for construction, and significantly reduces construction waste as well.  
                                </p>


                                <h3 class="carbon-benefits-content-head">
                                    Prospects and Possibilities
                                </h3>
                               
                                <br/>
                                <br/>
                               
                                <img class="blog-image" src={fantastic} alt=""/>
                                       
                                <br/>
                                <br/>

                                    <p class="carbon-text">
                                        As the technology continuously evolves, we can expect to convert more of our traditional building/manufacturing processes to automated 3D printing assembly lines. It would soon be possible to rapidly create concrete bridges for both emergency and routine use. The truss-like infill pattern prints on early 3D printed bridges are redefining the strength of concrete just by changing its configuration 
                                        <br/>
                                        <br/>
                                        Composite wall panels that combine strength and insulation properties produced through sheet lamination printing could soon be the norm for house walls. 
                                        <br/>
                                        <br/>
                                        3D printed custom furniture and other interior elements produced on a large scale could be the next step in the evolution of 3D printing; after all, we already have the technology to incorporate wood fibers in polymeric filaments such that the final print has the texture and even smell of real wood. 
                                        <br/>
                                        <br/>
                                        We are getting closer to a future where 3D printing is the norm Thus increasing support for the evolution of this technology and continuously adopting changes for a leaner production process are the fitting next steps.
                                        <br/>
                                        <br/>


                                        <i>
                                            -- Kristopher Ray Pamintuan, PhD is a licensed chemical engineer currently working as a full-time associate professor at Mapua University in the Philippines, teaching undergraduate and graduate courses in chemical engineering. He specializes in renewable bioenergy research and contributes to other fields of research such as 3D printing, electrochemistry applications, water and wastewater treatment, and many more. 
                                        </i>
                                    </p>


                                    <p class="carbon-text">
                                        Published on April 2022
                                    </p>


                                    <p class="carbon-text-blog">
                                        Share
                                        <br/>
                                    
                                        <a href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/Print_an_Entire_House_in_a_Day.html" target="_blank" rel="noreferrer noopener pt-3" class="flink-icon">
                                            <img class="blog-icon me-3" src={twitter} alt=""/>
                                        </a>
                                    </p>
                             

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>
  )
}

export default PrintanEntireHouse