import React from 'react'
import twitter from "../../images/Icons/twitter.svg";
import iron from "../../images/Blog/iron.PNG";
import frame from "../../images/Blog/frame.svg";
function OurQubesTowardCarbonNeutrality() {
  return (
   <>
   <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
            <div class="container p-3">
                <div class="carbon-content-container-bg">
                    <div class="d-sm-flex align-items-center justify-content-between">
                        <div class="container-sm">
                         
                            <h3 class="carbon-benefits-content">
                                Our Qubes Toward Carbon Neutrality
                            </h3>
                

                            <p class="carbon-text-blog">
                                Share
                                <br/>
                            
                                <a href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/Our_Qubes_Toward_Carbon_Neutrality.html" target="_blank" rel="noreferrer noopener pt-3" class="flink-icon">
                                    <img class="blog-icon me-3" src={twitter} alt=""/>
                                </a>
                            </p>
                      
                            
           
                            <p class="carbon-text ">
                                Engr. Kristopher Ray S. Pamintuan, Ph.D.
                                <br/>
                                Engr. Jazneil Bello
                                <br/>
                                Engr. Kristoffer Dave Tabong
                                
                            </p>

                            <img class="blog-image" src={frame} alt=""/>
                            <br/>
                            <br/>
                        
                                    <p class="carbon-text ">

                                        For us to achieve net-zero carbon emissions, we must keep disrupting the status quo. Presently, most homes, if not all, are composed of steel and concrete as their main structural elements. We have taken the steps to disrupt this status quo using our engineered composites. 
                                        <br/>
                                        <br/>
                                        Composites are materials that are made of two or more different materials that are sandwiched, laminated, or interspersed to create a new material that combines the best properties of the base materials. Composites can be formulated to be as strong (or even stronger) than steel and concrete. More importantly, composites also generate significantly fewer carbon emissions than steel and concrete. 
                                        As more people are becoming aware of the negative effects of Greenhouse Gases (like CO2) as it accelerates Global Warming and Climate Change, awareness of homebuilding materials that help mitigate CO2 emissions is what the people need to start their journey to carbon neutrality. Read on to know how much you are helping the environment by switching to composites.
                                        <br/>
                                        <br/>
                                        A useful methodology for quantifying the environmental impact of a product is by conducting a Life Cycle Assessment (LCA). An LCA allows us to look at how much emissions are attached to the manufacture, distribution, use, and disposal of a product. This is a good way to empower consumers by letting them know how much environmental damage their consumption causes. 
                                        <br/>
                                        <br/>
                                        To give you an idea of the usefulness of LCA, let us look at the emissions associated with cement, which is a component of the concrete mix commonly used in home construction. Carbon emissions from cement manufacturing alone can reach 0.9 kg per kg of cement produced (1). This does not yet include the carbon emissions from transporting the cement from the manufacturing plant to the construction site and the packaging materials used. For a 400-sqft house, approximately 6.5 tons of cement is needed (2), and that translates to 5.9 tons of CO2 emissions. That amount of CO2 emissions from a single average house is the equivalent of roughly 15000 kWh of electricity worth of emissions, enough to power the average Philippine household for 17 years (3). That amount of CO2 emissions is significant coming from a single material, and it is expected that the emissions will increase as our appetite for cement further increases to sustain housing demands from a growing population. Cement production contributes roughly 8% of the total global CO2 emissions, just below the total emissions from the entire agriculture sector at 9% (4).
                                        <br/>
                                        <br/>
                                    </p>

                                    <img class="blog-image" src={iron} alt=""/>
                                    <br/>
                                    <br/>
                                
                                  
                                    
                                    <p class="carbon-text ">
                                      
                                    Another common construction component is steel used for reinforcing concrete. The same 400-sqft from above will need approximately 320 kg of steel, and a conservative estimate of the carbon emissions of steel is around 1.85 kg CO2 per kg of steel (5). This results in CO2 emissions of 592 kg for the theoretical 400-sqft house. Although this is a lot smaller contribution compared to the cement, both are used in large amounts worldwide and thus contribute a lot in terms of emissions.
                                    <br/>
                                    <br/>
                                    Now let us consider a viable replacement: our composites. According to our estimates, replacing steel and concrete with composites reduces the overall carbon footprint of the construction by about 60% to 80%. Up to 80% reduction in carbon emissions from construction materials is a significant step towards carbon neutrality – when scaled up, the carbon footprint of the construction industry has the potential to shrink considerably. The reduction of carbon emissions to the atmosphere coming from all sectors is instrumental in keeping the average global temperature rise to less than 2oC compared to pre-industrial levels, consistent with the Paris Agreement.
                                    <br/>
                                    <br/>
                                    Of course, as the overall goal is carbon neutrality or net-zero carbon emissions, further steps should be done to bring down the remaining emissions to insignificant levels. Using composites for buildings will still generate emissions, albeit lower than the status quo (steel and concrete). This is where our QUBE comes in! QUBE tokens are tied to the international trade of carbon credits or carbon offsets (read our article on carbon credits), and those carbon credits will offset the remaining carbon footprint of composites which allows us to reach net-zero carbon emissions.
                                    <br/>
                                    <br/>
                                    Engr. Kristopher Ray S. Pamintuan, our Research Advisory on Global Sustainability, believes that:
                                    <br/>
                                    <br/>
                                    “The ability to brand your next house as carbon neutral is the best bragging rights you can get; you can show everyone that you took concrete steps (pun intended) towards real sustainability for the benefit of the planet and of the future generations to come. That power starts with Qubes.”
                                    <br/>
                                    <br/>
                                    Engr. Jazneil Bello, our Composite Design Engineer, focuses on the structural integrity of composite materials
                                    <br/>
                                    <br/>
                                    “Aside from sustainability contributions, composite materials also have the advantage of their design flexibility, low material cost, durability, and corrosion resistance. How structural parts module in Homeqube can cater to different arrangement types, which is a vital feature of a bottom-up construction. We envisioned, thru Homeqube, a sustainable home-building community.
                                    <br/>
                                    <br/>
                                    Engr. Kristoffer Dave Tabong, our Director of Data Systems, tackles the role of data science for carbon neutrality:
                                    <br/>
                                    <br/>
                                    "We are committed to capturing the benefits of using composite materials for carbon thru data science and artificial intelligence. By monitoring, predicting, and reducing carbon emissions of our products, we can provide insights into the carbon footprints and identify effective ways to create sustainable transformation."
                                    <br/>
                                    <br/>
                                   
                                    <i>
                                    At Homeqube, we promote a community-based approach to homebuilding as enabled by empowering technologies such as AI and blockchain. <a class="blog-link" href="/documents/HomeQube Brief 1.0 [with Whitepaper].pdf" target="blank">Read our whitepaper to learn more.</a>
                                     </i>
                                    <br/>
                                    <br/>
                                    Published on April 2022
                                    </p>
                         
                                 
                                    <p class="carbon-text-blog">
                                        Share
                                        <br/>
                                    
                                        <a href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/Our_Qubes_Toward_Carbon_Neutrality.html" target="_blank" rel="noreferrer noopener pt-3" class="flink-icon">
                                            <img class="blog-icon me-3" src={twitter} alt=""/>
                                        </a>
                                    </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   </>
  )
}

export default OurQubesTowardCarbonNeutrality