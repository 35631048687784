import React from "react";
import PhilosophyContent from "../modules/philosophy/PhilosophyContent";
import PhilosophyShowcase from "../modules/philosophy/PhilosophyShowcase";

function Philosophy() {
  return (
    <>
      <PhilosophyShowcase />
      <section>
        <div className="container yt-z-index">
          <iframe
            width="1000"
            height="590"
            src="https://www.youtube.com/embed/mjbRobYcCRk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </section>

      <PhilosophyContent />
    </>
  );
}

export default Philosophy;
