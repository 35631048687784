import React from 'react'
import DaoProjectsContent from '../modules/DAO-projects/DaoProjectsContent'
import DaoProjectsContentResponsive from '../modules/DAO-projects/DaoProjectsContentResponsive'

function DAOProjects() {
  return (
   <>
        <DaoProjectsContent/>
        <DaoProjectsContentResponsive/>
   </>
  )
}

export default DAOProjects