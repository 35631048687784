import React from 'react';

const ServicesCard = (props) => {
  const { title, content, image, btnname, btnlink } = props;

  return (
    <div className="our-services-card">
      <div className="our-services-card-content d-flex flex-column align-items-center justify-content-between gap-4">
        <div className="our-service-card-image">
          {image && (
            <img src={image} alt={title} />
          )}
        </div>
        <div className="our-service-card-title-container d-flex flex-column align-items-center justify-content-center">
          <h2 className="our-services-card-title">{title}</h2>
        </div>
        <div className="our-service-card-text-container d-flex flex-column align-items-center justify-content-center">
          <p className="our-services-card-text">{content}</p>
        </div>
        <div className="our-service-card-btn-container">
          <a href={btnlink} target="_blank" rel="noopener noreferrer">
            <button className="button6 btn-lg rounded-pill">
              {btnname}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
