import React from "react";
import twitter from "../../images/Icons/twitter.svg";
import jpcalma from "../../images/Blog/01_JP_Calma.jpg"
import pr2 from "../../images/Blog/PR_2.png"

function NextGenArchitecture() {
  return (
    <>
      <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
          <div class="container p-3">
            <div class="carbon-content-container-bg">
              <div class="d-sm-flex align-items-center justify-content-between">
                <div class="container-sm">
                  <h3 class="carbon-benefits-content">
                    A Calma Creates Next-Gen Architecture, Homebuilding
                    Ecosystem on Web3
                  </h3>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/News/A_Calma_Creates_Next-Gen_Architecture_Homebuilding_Ecosystem_on_Web3.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img
                        class="blog-icon me-3"
                        src={twitter}
                        alt=""
                      />
                    </a>
                  </p>

                  <br />
                  <br />
                  <img
                    class="blog-image1"
                    src={jpcalma}
                    alt=""
                  />
                  <br />

                  <p class="carbon-text-blog">
                    <br />
                    April 2022 – Jose Paolo Calma, hailing from the famed
                    Filipino family of architects and contractors, has dedicated
                    much of his life to the fast-paced, highly routine business
                    of building houses.
                  </p>

                  <p class="carbon-text-blog">
                    As CEO of interior construction pioneer Multi-Development &
                    Construction Corporation (MDCC), he would chase one deadline
                    after another while having his eyes open to the glaring
                    flaws of the industry: long lead times, staggering
                    inefficiencies in the design process, the overreliance on
                    human labor, unsustainable raw materials, and not to mention
                    the huge gap between e-commerce and the sector.
                    <br />
                    <br />
                  </p>

                  <h3 class="carbon-benefits-content-head">
                    Homebuilding for all
                  </h3>

                  <p class="carbon-text-blog">
                    “I wasn’t satisfied with how I delivered homes for the
                    longest time. Human labor could only get you so far in
                    quality and productivity. With all things on hold due to the
                    pandemic, how will you build if all workers are gone? How
                    will you address the shortage, the wasted time and
                    resources?” said Calma, a finance, entrepreneurship, and
                    construction engineering graduate from the De La Salle
                    University, the Asian Institute of Management (AIM), and
                    Massachusetts Institute of Technology (MIT).
                    <br />
                    <br />
                    Aware of how the world has become more machine-oriented, he
                    went on to launch a mobile app whose concept has long
                    simmered in his mind: Homeqube, premised on making
                    homebuilding accessible to all through a built-in network of
                    manufacturers and the benefits of artificial intelligence
                    (AI) and blockchain technology.
                    <br />
                    <br />
                  </p>
                  <h3 class="carbon-benefits-content-head">
                    Decentralized platform
                  </h3>

                  <p class="carbon-text-blog">
                    Simplicity with robustness underscores Calma’s vision and
                    industrialist outlook. Anyone with internet access can use
                    the Homeqube app, start designing their future home via
                    pre-programmed “Knobs” they can configure (the freemium
                    version provides the first set of Knobs), and readily access
                    information on cost, mobility and dynamics of home design
                    and construction as well as procure home parts directly from
                    verified manufacturers.
                    <br />
                    <br />
                    Calma emerges as a pioneer in this space as a decentralized
                    online platform for homebuilding has not been done before,
                    particularly in light of the long-term effects of global
                    pandemics, climate change, and cybersecurity issues in the
                    industry.
                    <br />
                    <br />
                    Homeqube operates at the heart of Web3.0, where
                    community-focused innovation powers the work of
                    decentralized autonomous organization (DAO) architecture,
                    parametric design, digital fabrication, project automation,
                    and smart contracting, to name a few. It makes a compelling
                    offer to already successful contractors, according to Calma.
                    <br />
                    <br />
                    The proposed ecosystem will also promote grassroots
                    entrepreneurship due to the machine efficiencies of Web3
                    that built environment (BE) agents naturally work with.
                    “Business is a continuous attrition, but working with
                    Homeqube empowers manufacturers, suppliers, and formal and
                    informal members of architecture, engineering, and
                    construction (AEC) benefit from their stake more effectively
                    along-side one another, across, and within,” he said.
                    <br />
                    <br />
                    He cites the benefits of system architecture and automation
                    instead of heavily depending on human output. As a result, a
                    Homeqube community member can fully function as a
                    homebuilder as they are able to make sense of the respective
                    requirements through the “Knobs.”
                    <br />
                    <br />
                    Homeqube rides on the rapidly growing digital economy with
                    the wealth of ways that users can engage in the app. Using
                    the app’s standard QUBE token, users can design and earn
                    tokens, join design game challenges, sell their designs as
                    non-fungible tokens (NFTs) once they become community
                    members, and even join the manufacturing network having
                    their own e-shopfront of 3D printed parts to sell across app
                    stakeholders.
                    <br />
                    <br />
                    “Token exchange gamification are the basic ways we’re
                    getting users to engage in the app. But the offer gets more
                    exciting from there as it has a snowball effect on communal
                    knowledge creation, where the true power of decentralization
                    lies,” Calma said.
                  </p>
                  <br />
                  <h3 class="carbon-benefits-content-head">
                    Reimagining the future
                  </h3>

                  <p class="carbon-text-blog">
                    Calma isn’t new to founding startups, as he is also engaged
                    in ventures in niche real estate development and yacht
                    manufacturing, where he deploys design for manufacture and
                    assembly (DFMA) for enhanced ease and efficiency in
                    processes, reliability, and quality.
                    <br />
                    <br />
                    Yet Homeqube is the “lifelong project” that assimilates
                    personal and professional lessons he has learned through the
                    years in building, rebuilding, and future-proofing the built
                    environment for himself and others.
                  </p>

                  <p class="carbon-text-blog">
                    “The world is fast creating increasingly decentralized,
                    democratized, digital societies. If we can do this in small
                    yet meaningful steps in homebuilding, in the longer term we
                    will be able cover the housing shortage since this will be
                    at least three times the speed of what we normally can cover
                    in 10 years. In using eco-friendly composites, we can save
                    dramatically slash carbon emission levels, making a better
                    home building environment for everyone,” Calma said.
                    <br />
                    <br />
                  </p>

                  <img class="img-breaker" src={pr2} alt="" />

                  <br />

                  <h3 class="carbon-benefits-content-head">About Homeqube</h3>
                  <p class="carbon-text-blog">
                    Homeqube is a homebuilding app powered by AI and blockchain
                    technology, enabling users to build their future home
                    through a built-in network of architecture, engineering, and
                    construction (AEC) experts and manufacturing agents on
                    Web3.0 empowering the entire industry at scale. It promotes
                    fast, quality, sustainable homebuilding processes,
                    demystifying residential construction and promoting
                    innovation, speed to market, and participation in the
                    digital economy and forming an empowered community of
                    homebuilders.
                    <br />
                    <br />
                  </p>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/News/A_Calma_Creates_Next-Gen_Architecture_Homebuilding_Ecosystem_on_Web3.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img
                        class="blog-icon me-3"
                        src={twitter}
                        alt=""
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NextGenArchitecture;
