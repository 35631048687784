import React, { useState } from "react";
import { Link } from "react-router-dom";
import blog1 from "../images/NewImages/newBlog/blog1.svg";
import blog2 from "../images/NewImages/newBlog/blog2.svg";
import blog3 from "../images/NewImages/newBlog/blog3.svg";
import blog4 from "../images/NewImages/newBlog/blog4.svg";
import blog5 from "../images/NewImages/newBlog/blog5.svg";
import blog6 from "../images/NewImages/newBlog/blog6.svg";
import blog7 from "../images/NewImages/newBlog/blog7.svg";
import rightArrow from "../images/NewImages/Arrow right-black.svg";

function NewBlogv2() {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="container pt-5">
        <div className="blog-title mb-3">Be in the know</div>
        <div class="row row-cols-1 row-cols-sm-3 g-3">
          <div class="col">
            <div class="card-blog">
              <img
                src={blog1}
                class="card-image-blog card-img-blog-top rounded-3"
                alt=""
              />
              <div class="card-body">
                <h5 class="new-card-title">
                  Is homebuilding ripe for a revolutionary overhaul?
                </h5>
                <p class="new-card-desc">
                  The current homebuilding industry, facing supply chain
                  delays,inefficiencies, and sustainability issues, has never
                  been more exposed to a wave of transformations than in today’s
                  fast-changing, tech-oriented world.
                </p>
              </div>
              <Link
                class="read-more-link mb-3"
                to="/blogs/is-home-building-ripe-for-a-revolutionary"
              >
                read more <img src={rightArrow} alt="" className="ms-1" />
              </Link>
            </div>
          </div>

          <div class="col">
            <div class="card-blog">
              <img src={blog2} class="card-image-blog card-img-blog-top rounded-3" alt="" />
              <div class="card-body">
                <h5 class="new-card-title">
                  NFTs for home parts and design explained.
                </h5>
                <p class="new-card-desc">
                  The internet is all abuzz over non-fungible tokens or NFTs,
                  but what benefits and possibilities do these digital assets
                  hold for home parts designers and similar creators?
                </p>
              </div>
              <Link
                class="read-more-link mb-3"
                to="/blogs/NFTs-for-home-parts-and-design"
              >
                read more <img src={rightArrow} alt="" className="ms-1" />
              </Link>
            </div>
          </div>

          <div class="col">
            <div class="card-blog">
              <img
                src={blog3}
                class="card-image-blog card-img-blog-top rounded-3"
                alt=""
              />
              <div class="card-body">
                <h5 class="new-card-title">
                  What is the bottom-up approach in homebuilding?
                </h5>
                <p class="new-card-desc">
                  Home construction has always followed a top-bottom planning
                  approach. It might be high time to consider a bottom-up
                  approach in the search for new efficiencies, greater cost
                  savings, and harnessing of new technologies.
                </p>
              </div>
              <Link
                class="read-more-link mb-3"
                to="/blogs/what-is-the-bottom-up-approach"
              >
                read more <img src={rightArrow} alt="" className="ms-1" />
              </Link>
            </div>
          </div>

          {show ? (
            <>
              <div class="col">
                <div class="card-blog">
                  <img
                    src={blog4}
                    class="card-image-blog card-img-blog-top rounded-3"
                    alt=""
                  />
                  <div class="card-body">
                    <h5 class="new-card-title">
                      What happens when architecture, AI and blockchain collide?
                      A DAO-based system architecture.
                    </h5>
                    <p class="new-card-desc">
                      Homeqube incorporates the best of architecture and
                      emerging technologies such as AI and blockchain in a
                      decentralized platform, anchored on a community-focused
                      approach, that pushes for homebuilding for all.
                    </p>
                  </div>
                  <Link
                    class="read-more-link mb-3"
                    to="/blogs/architecture-ai-and-blockchain"
                  >
                    read more <img src={rightArrow} alt="" className="ms-1" />
                  </Link>
                </div>
              </div>

              <div class="col">
                <div class="card-blog">
                  <img
                    src={blog5}
                    class="card-image-blog card-img-blog-top rounded-3"
                    alt=""
                  />
                  <div class="card-body">
                    <h5 class="new-card-title">
                      Print an Entire House in a Day? The Benefits of
                      Possibilities of 3D Printing in Homebuilding.
                    </h5>
                    <p class="new-card-desc">
                      By Kristopher Ray Pamintuan, PhD
                    </p>
                  </div>
                  <Link
                    class="read-more-link mb-3"
                    to="/blogs/print-an-entire-house"
                  >
                    read more <img src={rightArrow} alt="" className="ms-1" />
                  </Link>
                </div>
              </div>

              <div class="col">
                <div class="card-blog">
                  <img
                    src={blog6}
                    class="card-image-blog card-img-blog-top rounded-3"
                    alt=""
                  />
                  <div class="card-body">
                    <h5 class="new-card-title">
                      The future of the built environment.
                    </h5>
                    <p class="new-card-desc">
                      Disruptive technologies, the rise of healthy materials,
                      and the prospect of smart design and smart cities are all
                      taking the construction industry by storm. What does the
                      future hold for the sector in light of these drastic
                      changes?
                    </p>
                  </div>
                  <Link
                    class="read-more-link mb-3"
                    to="/blogs/the-future-of-the-build-environment"
                  >
                    read more <img src={rightArrow} alt="" className="ms-1" />
                  </Link>
                </div>
              </div>

              <div class="col">
                <div class="card-blog">
                  <img
                    src={blog7}
                    class="card-image-blog card-img-blog-top rounded-3"
                    alt=""
                  />
                  <div class="card-body">
                    <h5 class="new-card-title">
                    Our Qubes Toward Carbon Neutrality.
                    </h5>
                    <p class="new-card-desc">
                    Engr. Kristopher Ray S. Pamintuan,
                    <br/>
                    Ph.D. Engr. Jazneil Bello,
                    <br/>
                    Engr. Kristoffer Dave Tabong
                    </p>
                  </div>
                  <Link
                    class="read-more-link mb-3"
                    to="/blogs/our-qubes-toward-carbon-neutrality"
                  >
                    read more <img src={rightArrow} alt="" className="ms-1" />
                  </Link>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="container">
          <div className="d-flex  p-5 d-flex justify-content-center">
            {!show ? (
              <button
                className="eco-button1 btn btn-outline-dark btn-lg rounded-pill mb-3 ms-2"
                onClick={() => setShow(true)}
              >
                Load More
              </button>
            ) : (
              <button
                className="eco-button1 btn btn-outline-dark btn-lg rounded-pill mb-3 ms-2"
                onClick={() => setShow(false)}
              >
                See less
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewBlogv2;
