import React from "react";
import newDiagram from "../../images/NewImages/diagramLatest.svg"
// import diagram from "../../images/Pipeline/Diagram.svg";

function SED() {
  return (
    <>
      <section class="content-eco1-bg-2 pb-5">
        <div class="col link d-flex align-items justify-content-center p-5">
          <div class="container">
            <div class="content-bg">
              <div className="row sed-content">
                <div className="col">
                  <div className="SED-title text-start mb-5">
                    Community Growth
                    <br /> on web 3.0 and
                    <br /> Industry 5.0
                  </div>
                  <div className="SED-content text-start">
                    “A decentralized platform is about 
                    leveraging community potential for better 
                    industry performance.”
                  </div>
                </div>
                <div className="col">
                  <img class="img-fluid" src={newDiagram} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SED;
