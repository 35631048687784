import React from "react";

function Terms() {
  return (
    <>
      <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
          <div class="container p-3">
            <div class="carbon-content-container-bg">
              <div class="d-sm-flex align-items-center justify-content-between">
                <div class="container-sm text-uppercase">
                  <h3 class="carbon-benefits-content">HOMEQUBE Terms of Use</h3>
                  <h3 class="carbon-benefits-content">
                    Welcome to HOMEQUBE App
                  </h3>
                  <p class="carbon-text ">
                    These Terms and Conditions of Use (the “Terms of Use”) apply
                    to the HOMEQUBE App located at homeqube.com. By using our
                    App, you are agreeing to comply with and be bound by the
                    following terms of use. Please review the following terms
                    carefully. If you do not agree to these terms, you should
                    not use this App.
                    <br />
                    <br />
                    The term “HOMEQUBE”, “us,” “we” or “our” refers to HOMEQUBE
                    Pte. Ltd. and the other partnerships, corporations and other
                    undertakings that are authorized to carry the name
                    “HOMEQUBE” or one or more of those undertaking as the
                    context requires. The term “you” refers to the user or
                    viewer of our App.
                  </p>
                  <h3 class="carbon-benefits-content">
                    Acceptance of Agreement
                  </h3>
                  <p class="carbon-text ">
                    You hereby agree to the terms and conditions set forth in
                    this Terms of Use Agreement (“Agreement”) with respect to
                    our App (the “App”).
                    <br />
                    <br />
                    This Agreement constitutes the entire and only agreement
                    between us and you, and supersedes all prior or
                    contemporaneous agreements, representations, warranties and
                    understandings with respect to the App and the subject
                    matter of this Agreement.
                    <br />
                    <br />
                    This Agreement may be amended at any time by us from time to
                    time without specific notice to you. The latest version of
                    the Agreement will be posted on the App, and you should
                    review this Agreement prior to using the App.
                  </p>
                  <h3 class="carbon-benefits-content">Copyright</h3>
                  <p class="carbon-text ">
                    The content, organization, graphics, design, compilation,
                    magnetic translation, digital conversion and other matters
                    related to the App are protected under applicable
                    copyrights, trademarks and other proprietary (including but
                    not limited to intellectual property) rights.
                    <br />
                    <br />
                    The copying, redistribution, use or publication by you of
                    any such matters or any part of the App, except as allowed
                    by Section 3 below, is strictly prohibited. You do not
                    acquire ownership rights to any article, document or other
                    materials viewed through the App.
                    <br />
                    <br />
                    The posting of information or materials on the App does not
                    constitute a waiver of any right in such information and
                    materials.
                  </p>
                  <h3 class="carbon-benefits-content">
                    Restrictions and Prohibitions on Use
                  </h3>
                  <p class="carbon-text ">
                    Your license for access and use of the App and any
                    information, materials or documents (collectively defined as
                    “Content and Materials”) therein are subject to the
                    following restrictions and prohibitions on use:
                    <br />
                    <br />
                    (a) You may not copy, print (except for the express limited
                    purpose permitted by Section 3 above), republish, display,
                    distribute, transmit, sell, rent, lease, loan or otherwise
                    make available in any form or by any means all or any
                    portion of the App or any Content and Materials retrieved
                    therefrom;
                    <br />
                    <br />
                    (b) You may not use the App or any materials obtained from
                    the App to develop, of as a component of, any information,
                    storage and retrieval system, database, information base, or
                    similar resource (in any media now existing or hereafter
                    developed), that is offered for commercial distribution of
                    any kind, including through sale, license, lease, rental,
                    subscription, or any other commercial distribution
                    mechanism;
                    <br />
                    <br />
                    (c) You may not create compilations or derivative works of
                    any Content and Materials from the App;
                    <br />
                    (d) use any Content and Materials from the App in any manner
                    that may infringe any copyright, intellectual property
                    right, proprietary right, or property right of us or any
                    third parties;
                    <br />
                    <br />
                    (e) You may not remove, change or obscure any copyright
                    notice or other proprietary notice or terms of use contained
                    in the App;
                    <br />
                    <br />
                    (f) You may not make any portion of the App available
                    through any timesharing system, service bureau, the Internet
                    or any other technology now existing or developed in the
                    future;
                    <br />
                    <br />
                    (g) You may not remove, decompile, disassemble or reverse
                    engineer any App software or use any network monitoring or
                    discovery software to determine the App architecture;
                    <br />
                    <br />
                    (h) You may not use any automatic or manual process to
                    harvest information from the App;
                    <br />
                    <br />
                    (i) You may not use the App for the purpose of gathering
                    information for or transmitting (1) unsolicited commercial
                    e-mail; (2) e-mail that makes use of headers, invalid or
                    nonexistent domain names, or other means of deceptive
                    addressing; and (3) unsolicited telephone calls or facsimile
                    transmissions;
                    <br />
                    <br />
                    (j) You may not use the App in a manner that violates any
                    state or federal law regulating e-mail, facsimile
                    transmissions or telephone solicitations; and
                    <br />
                    <br />
                    (k) You may not export or re-export the App or any portion
                    thereof, or any software available on or through the App, in
                    violation of the export control laws or regulations of the
                    United States.
                  </p>
                  <h3 class="carbon-benefits-content">
                    Errors, Corrections and Changes
                  </h3>
                  <p class="carbon-text ">
                    We do not represent or warrant that the App will be
                    error-free, free of viruses or other harmful components, or
                    that defects will be corrected. We do not represent or
                    warrant that the information available on or through the App
                    will be correct, accurate, timely or otherwise reliable. We
                    may make changes to the features, functionality or content
                    of the App at any time. We reserve the right in our sole
                    discretion to edit or delete any documents, information or
                    other content appearing on the App.
                  </p>
                  <h3 class="carbon-benefits-content">Unlawful Activity</h3>
                  <p class="carbon-text ">
                    We reserve the right to investigate complaints or reported
                    violations of this Agreement and to take any action we deem
                    appropriate, including but not limited to reporting any
                    suspected unlawful activity to law enforcement officials,
                    regulators, or other third parties and disclosing any
                    information necessary or appropriate to such persons or
                    entities relating to your profile, e-mail addresses, usage
                    history, IP addresses and traffic information.
                  </p>
                  <h3 class="carbon-benefits-content">Disclaimer</h3>
                  <p class="carbon-text ">
                    THE INFORMATION, CONTENT AND DOCUMENTS FROM OR THROUGH THE
                    APP ARE PROVIDED “AS-IS,” “AS AVAILABLE,” WITH “ALL FAULTS”,
                    AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE DISCLAIMED
                    (INCLUDING BUT NOT LIMITED TO THE DISCLAIMER OF ANY IMPLIED
                    WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                    PURPOSE). THE INFORMATION AND APP MAY CONTAIN BUGS, ERRORS,
                    PROBLEMS OR OTHER LIMITATIONS. WE AND OUR AFFILIATED PARTIES
                    HAVE NO LIABILITY WHATSOEVER, EXCEPT AS PROVIDED IN SECTION
                    17(b). IN PARTICULAR, BUT NOT AS A LIMITATION THEREOF, WE
                    AND OUR AFFILIATED PARTIES ARE NOT LIABLE FOR ANY INDIRECT,
                    SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING
                    DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION,
                    OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF
                    WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR
                    OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES. THE NEGATION AND LIMITATION OF DAMAGES SET FORTH
                    ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
                    BETWEEN US AND YOU. THIS APP AND THE INFORMATION PRESENTED
                    WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS. NO ADVICE OR
                    INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
                    US THROUGH THE APP OR OTHERWISE SHALL CREATE ANY WARRANTY,
                    REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS
                    AGREEMENT.
                    <br />
                    <br />
                    ALL RESPONSIBILITY OR LIABILITY FOR ANY DAMAGES CAUSED BY
                    VIRUSES CONTAINED WITHIN THE ELECTRONIC FILE CONTAINING A
                    FORM OR DOCUMENT IS DISCLAIMED.
                  </p>
                  <h3 class="carbon-benefits-content">
                    Limitation of Liability
                  </h3>
                  <p class="carbon-text ">
                    (a) We and any Affiliated Party shall not be liable for any
                    loss, injury, claim, liability, or damage of any kind
                    resulting in any way from (i) any errors in or omissions
                    from the App or information obtained, (ii) the
                    unavailability or interruption of the App or any features
                    thereof, (iii) your use of the App, (iv) the content
                    contained on the App, or (v) any delay or failure in
                    performance beyond the control of an Affiliated Party.
                    <br />
                    <br />
                    (b) THE AGGREGATE LIABILITY OF US AND THE AFFILIATED PARTIES
                    IN CONNECTION WITH ANY CLAIM ARISING OUT OF OR RELATING TO
                    THE APP SHALL NOT EXCEED $100 AND THAT AMOUNT SHALL BE IN
                    LIEU OF ALL OTHER REMEDIES WHICH YOU MAY HAVE AGAINST US AND
                    ANY AFFILIATED PARTY.
                  </p>
                  <h3 class="carbon-benefits-content">
                    Copyrights and Copyright Agents
                  </h3>
                  <p class="carbon-text ">
                    We respect the intellectual property of others, and we ask
                    you to do the same. If you believe that your work has been
                    copied in a way that constitutes copyright infringement,
                    please provide our Copyright Agent the following
                    information:
                    <ul class="carbon-text">
                      <li>
                        An electronic or physical signature of the person
                        authorized to act on behalf of the owner of the
                        copyright interest;
                      </li>
                      <li>
                        A description of the copyrighted work that you claim has
                        been infringed;
                      </li>
                      <li>
                        A description of where the material that you claim is
                        infringing is located on the App;
                      </li>
                      <li>
                        Your address, telephone number, and e-mail address;
                      </li>
                      <li>
                        A statement by you that you have a good faith belief
                        that the disputed use is not authorized by the copyright
                        owner, its agent, or the law; and
                      </li>
                      <li>
                        A statement by you, made under penalty of perjury, that
                        the above information in your Notice is accurate and
                        that you are the copyright owner or authorized to act on
                        the copyright owner’s behalf.
                      </li>
                      <li>
                        Our Copyright Agent for Notice of claims of copyright
                        infringement on the App can be reached by directing an
                        e-mail to the Copyright Agent.
                      </li>
                    </ul>
                  </p>

                  <h3 class="carbon-benefits-content">Legal Compliance</h3>
                  <p class="carbon-text ">
                    You agree to comply with all applicable domestic and
                    international laws, statutes, ordinances and regulations
                    regarding your use of the App and the Content and Materials
                    provided therein.
                  </p>
                  <h3 class="carbon-benefits-content">Miscellaneous</h3>
                  <p class="carbon-text ">
                    This Agreement shall be treated as though it were executed
                    and performed in New York, NY and shall be governed by and
                    construed in accordance with the laws of the State of New
                    York (without regard to conflict of law principles).
                    <br />
                    <br />
                    Any cause of action by you with respect to the App must be
                    instituted within one (1) year after the cause of action
                    arose or be forever waived and barred.
                    <br />
                    <br />
                    All actions shall be subject to the limitations set forth in
                    Section 16 and Section 17.
                    <br />
                    <br />
                    The language in this Agreement shall be interpreted as to
                    its fair meaning and not strictly for or against any party.
                    <br />
                    <br />
                    Any rule of construction to the effect that ambiguities are
                    to be resolved against the drafting party shall not apply in
                    interpreting this Agreement.
                    <br />
                    <br />
                    The headings in this Agreement are included for convenience
                    only and shall neither affect the construction or
                    interpretation of any provision of this Agreement nor affect
                    any of the rights or obligations of the parties to this
                    Agreement.
                    <br />
                    <br />
                    Should any part of this Agreement be held invalid or
                    unenforceable, that portion shall be construed as much as
                    possibly consistent with applicable law and the remaining
                    portions shall remain in full force and effect.
                    <br />
                    <br />
                    To the extent that anything in or associated with the App is
                    in conflict or inconsistent with this Agreement, this
                    Agreement shall take precedence. Our failure to enforce any
                    provision of this Agreement shall not be deemed a waiver of
                    such provision nor of the right to enforce such provision.
                    <br />
                    <br />
                    Our rights under this Agreement shall survive any
                    termination of this Agreement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms;
