import React, { useState } from "react";
import { Link } from "react-router-dom";
import rightArrow from "../images/NewImages/Arrow right-black.svg";
import newsData from "../newsData";

function NewNews() {
  const [noOfElement, setnoOfElement] = useState(3);
  // eslint-disable-next-line no-unused-vars
  const loadMore = () => {
    setnoOfElement(noOfElement + noOfElement);
  };

  const slice = newsData.cardData.slice(0, noOfElement);
  return (
    <>
      <div className="container pt-5 mb-5">
        <div className="blog-title mb-4">Be updated</div>
        <div class="row row-cols-1 row-cols-sm-2 g-3 justify-content-around">
          {slice.map((item, index) => {
            return (
              <>
                <div class="col">
                  <div class="card-blog">
                    <img
                      src={item.img}
                      class="card-image-blog card-img-blog-top rounded-3"
                      alt=""
                    />
                    <div class="card-body">
                      <h5 class="new-card-title">{item.title}</h5>
                      <p class="new-card-desc">
                        {item.desc}
                        <br />
                        {item.desc2}
                        <br />
                        {item.desc3}
                      </p>
                    </div>
                    <Link class="read-more-link mb-3" to={item.link}>
                      read more <img src={rightArrow} alt="" className="ms-1" />
                    </Link>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        {/* <div className="container">
          <div className="d-flex  p-5 d-flex justify-content-center">
            <button
              className="eco-button1 btn btn-outline-dark btn-lg rounded-pill mb-3 ms-2"
              onClick={() => loadMore()}
            >
              Load More
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default NewNews;
