import React from 'react'
import faqsimages from "../faqs/image1.png"


function FaqsContent() {
  return (
  <>
          <section class="p-4 text-dark text-start mx-4">
            <div class="accordion accordion-flush " id="accordionFlush">
                <div class="accordion-item ">
                  <h2 class="accordion-header " id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <b class="text-uppercase">What is Homeqube?</b>
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlush">
                    <div class="accordion-body">
                        <p class="accordion-text text-uppercase">Homeqube is a homebuilding app powered by AI to help you visualize your future home. We’ve got a built-in network of manufacturers, suppliers, contractors, architects, and engineers here to help you optimize your design according to your budget, taste, and lifestyle.</p> 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        <b class="text-uppercase">How is homebuilding on the app better than the tried-and-tested traditional route?</b>
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlush">
                    <div class="accordion-body text-uppercase">
                        <p class="accordion-text">Homeqube solves many of the recurring problems in homebuilding, including the following:</p>
                        <ul class="accordion-text">
                            <li>Long lead time</li>
                            <li>Lack of publicly available information in architecture and engineering</li>
                            <li>No direct connection to manufacturers in the design process</li>
                            <li>Missing linkage between e-commerce and house design</li>
                            <li>High carbon emissions from construction materials</li>
                            <li>Inefficiencies of skilled labor and design process (Think of mobility restrictions during the COVID-19 pandemic!)</li>
                            <li>Low resilience of most residential structure against strong winds and earthquakes</li>
                            <li>Industrial challenges in Industry 4.0 construction</li>
                        </ul>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        <b class="text-uppercase">I’d like to design my own future home. How can I do it on the app?</b>
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlush">
                    <div class="accordion-body text-uppercase">
                        <p class="accordion-text">We will guide you through the entire process, including in the following areas:</p>
                        <ul class="accordion-text">
                            <li>Automatically plotting your lot area through optical character recognition (OCR)</li>
                            <li>Designing your home using our knobs or user-centric robust controls</li>
                            <li>Generating essential documents you would need to build your house before move-in</li>
                            <li>Providing crucial information on cost, mobility, and dynamics for you to make informed decisions</li>
                        </ul>
                        <p class="accordion-text">
                            At the heart of the app are the microsystems to build your house. Through these microsystems anchored on a deconstruction philosophy, produce various combinations of designs from the basic parts of the structure. Operate these microsystems using knobs to modify the design of your house, based on your preferences. 
                        </p>

                        <p class="accordion-text">
                            As you turn the knobs, you will see real-time implications in the cost of your home, lifestyle metrics, mobility metrics, and other values to guide you in the design. As the app’s name implies, the massing of the home is based on cubes. 
                        </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                          <b class="text-uppercase">How do I get access to these knobs?</b>
                      </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text">
                            The freemium version of the app will provide the first set of knobs, which can produce cube massing. The arrangement of the cubes will depend on the three knobs, namely the generation path knob, density knob, and complexity knob.
                          </p>
                          <p class="accordion-text">
                            You may arrange various combinations of the cubes, which are constrained within the allowable buildable area and a maximum number of stories indicated in the input. More knobs will be developed and thus expand the possibilities of home design in-app. You’ll surely have loads of fun in the process! 
                          </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                          <b class="text-uppercase">What’s a QUBE token? </b>
                      </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text">
                            QUBE is a utility token that can be used inside the Homeqube app. These tokens are pre-mined and can be exchanged for Solana (SOL). 
                          </p>
                          <p class="accordion-text">
                            Users get an added dose of fun using Homeqube through QUBE tokens and gamification. The more you engage, the more we can glean on your activities and provide better value to your homebuilding venture. Learn more about <a href="/qube-tokenomics">QUBE Tokenomics. </a>
                          </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                          <b class="text-uppercase">What is Solana?</b>
                      </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text">
                            We have chosen Solana as the most efficient and low-energy blockchain technology by far. Solana is a web-scale blockchain that provides fast, secure, scalable, decentralized apps. This platform is considered a contender to Ethereum because of its lower costs and higher transaction speed. 
                          </p>
                          <p class="accordion-text">
                            Solana has prioritized smart contracts. These are programs stored on the blockchain that will run when predetermined conditions are met, and automate agreement so that everyone is certain of the outcome. This move allows Solana to take advantage of the non-fungible token (NFT) markets.
                          </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSeven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                          <b class="text-uppercase">What are the types of documents I will receive once I have designed my home using the app?</b>
                      </button>
                    </h2>
                    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text">
                            Upon completing your design inside the app, you can generate documents for the building permit application as well as an assembly manual. 
                          </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingEight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                          <b class="text-uppercase">Where can I get the special composite parts for my home construction?</b>
                      </button>
                    </h2>
                    <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text">
                            Homeqube gives you the freedom to source the parts and products for your home. Some of the potential sources include purchasing from affiliated franchises, self-manufacturing through 3D printing, and finding external suppliers to create your parts. The sky’s the limit! 
                          </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingNine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                          <b class="text-uppercase">I’m a designer interested in creating parts and home design. How can I do it?</b>
                      </button>
                    </h2>
                    <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text">
                            Earn QUBE tokens every time your created part and design are recognized and accepted by the Homeqube system. In addition, you will have ownership of that part through NFTs. This way, you can create your digital intellectual property for other designers. 
                          </p>

                          <p class="accordion-text">
                            NFT creation gives graphics artists and generative designers like you the freedom to sell their digital work and protect their intellectual property. Here at Homeqube, we invite computer graphics experts, enthusiasts, and learners in making creative digital works to improve home building design about generative concepts, which can directly be used for homebuilding design processes. 
                          </p>
                          <div class="faqs-image-1">
                          <img src={faqsimages} class="img-fluid" alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTen">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                          <b class="text-uppercase">How do I become a metaverse contractor?</b>
                      </button>
                    </h2>
                    <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text">
                            Through Homeqube VR environment, we will offer virtual experiences in building homes in this metaverse. This virtual world will allow you to collaborate your home creations with your clients from the comfort of your home. In augmentation with parts creation from the users, they can use these parts in building their homes.  
                          </p>
                          <p class="accordion-text">
                            This feature is best suited for contractors and designers who want to show their design to clients through this app, or graphic artists keen to earn a token and sell it to NFT markets.
                          </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingEleven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                          <b class="text-uppercase">How can I become a Homeqube manufacturer? </b>
                      </button>
                    </h2>
                    <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text">
                            There are three ways you can be a manufacturer in the app:  
                          </p>
                          <ol class="accordion-text">
                            <li>Become a member of the franchise manufacturers of our composite parts</li>
                            <li>Become a member of additive manufacturers using #D printing, giving you the freedom to introduce your products to our parts ecosystem.</li>
                            <li>Be part of our designers’ community to find potential parts for manufacturing.</li>
                        </ol>
                        <p class="accordion-text">
                            If you’re interested in franchise manufacturing, kindly email us at <a href="mailto:manufacturing@homeqube.com" target="_blank" rel='noreferrer'>
                              manufacturing@homeqube.com.
                            </a>   
                          </p>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwelve">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                          <b class="text-uppercase">How is smart contracting at Homeqube different from traditional contracting? </b>
                      </button>
                    </h2>
                    <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlush">
                      <div class="accordion-body text-uppercase">
                          <p class="accordion-text ">
                            Traditional construction practices and compliances entail long wait times, added effort, and full reliance on third-party validation for contractors. Traditional house construction also takes several months from design to commissioning.   
                          </p>
                          
                        <p class="accordion-text">
                            In contrast, Homeqube pre-engineering parts and low labor skill requirements for assembly significantly slash the amount of time needed to build homes -- while maintaining utmost quality.
                          </p>
                          <section class="showcase1 bg-light text-dark pt-5 pb-5 text-center text-sm-start">
                            <div class="container text-uppercase">
                                <table class="table-responsive-sm table-striped table-borderless table caption-top">
                                    <thead>
                                        <tr class="q-tokenomics-type-row-1">
                                             
                                            <th scope="col" class="faqs-th-position">Traditional Construction</th>
                                            <th scope="col" class="faqs-th-position">Homeqube Smart Construction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                           
                                            <td class="faq-table-content-1">Hire Professionals to Design your home </td>
                                            <td class="faq-table-content-1-1">Usage of Knobs Control to Design your home</td>
                                        </tr>
                                        <tr>
                                            
                                            <td class="faq-table-content-1">Manual creation of plans, specifications, and bill of materials for building permit</td>
                                            <td class="faq-table-content-1-1">Automated creations of plans, specification, assembly manual, and bill of materials for building permit</td>
                                        </tr>
                                        <tr>
                                         
                                            <td class="faq-table-content-1">Hire a traditional contractor to build your home</td>
                                            <td class="faq-table-content-1-1">Anyone can be a builder of your DIY home project due to Lowered Skilled Requirement for Pre-Engineered Parts</td>
                                        </tr>
                                        <tr>

                                            <td class="faq-table-content-1">Canvassing, Procurement, and Delivery of Materials</td>
                                            <td class="faq-table-content-1-1">Shopfront for buying items in the bill of materials, and delivery from nearest manufacturer stores</td>
                                        </tr>
                                        <tr>
                                            
                                            <td class="faq-table-content-1">Traditional cast-in-place construction</td>
                                            <td class="faq-table-content-1-1">Assembly construction from Homeqube Parts</td>
                                        </tr>
                                  
                                       
                                    </tbody>
                                </table>
                            </div>  
                        </section>
                      </div>
                    </div>
                  </div>
              </div>
        </section>
  </>
  )
}

export default FaqsContent