import React from "react";
import { Link } from "react-router-dom";

function FaqsFootbar() {
  return (
    <>
      <section class="footbar text-light pb-5">
        <div class="container">
          <div class="d-md-flex justify-content-between align-items-center">
            <h3 class="footbar-text">
              Homeqube is the future of homebuilding
              <p class="footbar-text-lead text-uppercase">
                We are using AI and blockchain to make the process easier,
                faster, and more accessible.
              </p>
            </h3>
            <div class="footbar-btn pb-2">
              <Link
                to="/documents/HomeQube Brief 1.0 [with Whitepaper].pdf"
                target="_blank"
                role="button"
                download
                class="footbar-button btn btn-lg rounded-pill"
              >
                Read our Whitepaper
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqsFootbar;
