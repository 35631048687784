import React from 'react'
import twitter from "../../images/Icons/twitter.svg";
import phone from "../../images/Blog/phone.jpg"
import smartcity from "../../images/Blog/smartcity.jpg"
import blog6 from "../../images/NewImages/newBlog/blog6.svg"
function TheFutureOfBuiltEnivorment() {
  return (
    <>
    <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
            <div class="container p-3">
                <div class="carbon-content-container-bg">
                    <div class="d-sm-flex align-items-center justify-content-between">
                        <div class="container-sm">
                         
                            <h3 class="carbon-benefits-content">
                                The future of the built environment 
                            </h3>
                

                            <p class="carbon-text-blog">
                                Share
                                <br/>
                            
                                <a href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/The_future_of_the_built_environment.html" target="_blank" rel="noreferrer noopener pt-3" class="flink-icon">
                                    <img class="blog-icon me-3" src={twitter} alt=""/>
                                </a>
                            </p>
                      
                        
                                    <p class="carbon-text ">

                                        <br/>
                                        <br/>
                                        Disruptive technologies, the rise of healthy materials, and the prospect of smart design and smart cities are all taking the construction industry by storm. What does the future hold for the sector in light of these drastic changes?   </p>

                                    <img class="blog-image" src={blog6} alt=""/>

                                    
                                    <p class="carbon-text ">
                                        <br/>
                                        <br/>
                                        What does the future hold for construction and the built environment in the wake of the worst global pandemic in modern times? 
                                        <br/>
                                        <br/>
                                        Back in 2017, the World Economic Forum’s annual summit in Davos, Switzerland <a class="blog-link"  target="_blank" href="https://www.designingbuildings.co.uk/wiki/The_future_of_the_built_environment_in_a_revolutionary_age" rel='noreferrer'>  tackled </a> the impact of the 4th Industrial Revolution. There was much buzz around increased digital connectivity via the Internet of Things (IoT), which would create new ways to serve customers, operationalize new production capabilities, and mobilize new devices across the sector. 
                                        <br/>
                                        <br/>
                                        Environmental and social shifts were also on the horizon: the increasing burden on the planet as it sustains ever-growing economies and populations. 
                                        
                                    </p>
                         
                                    <h3 class="carbon-benefits-content-head">
                                        Trends and patterns
                                    </h3>
                                    <br/>
                                    <br/>
                                    
                                    <img class="blog-image" src={phone} alt=""/>

                                    


                                    <p class="carbon-text ">
                                        <br/>
                                        <br/>
                                        Health and sustainability
                                        <br/>
                                        Construction businesses are looking to <a class="blog-link"  target="_blank" href="https://nla.london/news/me-insights-the-future-of-the-built-environment" rel='noreferrer'>  focus </a> on using healthy materials in the built environment, prioritize good indoor air quality, and consider developing good for second and third life cycles, to name a few. 
                                        <br/>
                                        <br/>
                                        This newfound focus comes on the heels of retail closures and mobility restrictions, along with the emphasis on hygiene and sanitation, due to COVID-19. Housing demand as well as new planning laws have paved the way for changing empty shops into housing spaces, contributing to a boom in residential building. 
                                        <br/>
                                        <br/>
                                        A “healthy by design” approach is therefore increasingly penetrating both home and commercial building.
                                        <br/>
                                        <br/>
                                        AI and automation
                                        <br/>
                                        Artificial intelligence (AI) is already changing the way architecture, engineering, and construction (AEC) works. The planning process, for instance, is deemed easier as architects gain access to new models, countless amounts of data, and new cost estimates. 
                                        <br/>
                                        <br/>
                                        Most of all, AI also looms large in assisting with construction with little to no manpower required. 
                                        <br/>
                                        <br/>
                                        As we speak, MIT researchers are <a class="blog-link"  target="_blank" href="https://interestingengineering.com/5-ways-artificial-intelligence-is-changing-architecture#:~:text=AI%20will%20make%20the%20planning,shorten%20design%20and%20building%20time." rel='noreferrer'> crafting </a> AI-powered drones that can communicate with one another to construct small models. 
                                        <br/>
                                        <br/>
                                        Smart design, smart cities
                                        <br/>
                                        There’s also much consideration around smart designs and using IoT to increase safety, convenience, and wellbeing inside homes. 
                                        <br/>
                                        <br/>
                                        Think of radiators that switch themselves on when the weather gets cold, or hidden sensors in medicine cabinets to update prescriptions. There’s also the prospect of smart cement equipped with sensors to track cracks and stresses. 
                                        <br/>
                                        <br/>
                                        The built environment of tomorrow, too, is likely to look very different from today, particularly in the world’s advanced cities. AI-backed smart cities are likely to emerge, driven by real-time data and feedback and enabling smartphones, cars, and buildings to communicate with each other for better living conditions. 
                                     
                                    </p>

                                   
                                    <h3 class="carbon-benefits-content-head">
                                        But is the built environment playing catch up?
                                    </h3>
                                    <br/>
                                    <br/>


                                    <img class="blog-image" src={smartcity} alt="" />
                                       
                                    <br/>
                                    <br/>


                                    <p class="carbon-text ">
                                    
                                        While societies are moving toward an increasingly digital mode, a number of industry experts argue that the built environment has been slower to react to the barrage of changes happening. 
                                        <br/>
                                        <br/>
    
                                        “There’s much about the industry that clutches tightly to the status quo,” notes home construction CEO and Homeqube founder JP Calma. “This status quo has worked for decades, so there’s been little incentive for many industry professionals to shake things up, not use energy-intensive cement and steel, and expedite home building timelines.” 
                                        <br/>
                                        <br/>
    
                                        Yet with the advent of <a class="blog-link"  href="/resources-blogs-architecture-ai-and-blockchain">  disruptive technologies, from AI and machine learning to blockchain, </a> there’s a lot of uncertainty around the future that a close understanding of building for the future is much warranted. 
                                        <br/>
                                        <br/>
    
                                        Movers and shakers in homebuilding, for example, are exploring a community-driven approach in the Web3 environment. 
                                        <br/>
                                        <br/>
    
                                        While not yet fully realized, Web3 makes it easy and practical to imagine various cryptocurrencies running on blockchain, fueling different transactions online. Knowledge is more easily shared and disseminated, promoting informed decision-making among clients who otherwise know little about engineering and architecture. 
                                        <br/>
                                        <br/>
    
                                        Reimagining the future of the built environment comes easy in the face of key trends, new disruptions, and expert takes on how to “future-proof” such a huge and well-entrenched industry. Yet it’s a big, and in fact necessary, responsibility to better shape building design, performance, and simply the way people live, work, and play tomorrow.
                                        <br/>
                                        <br/>
    

                                        <i>
                                        Homeqube harnesses empowering technologies such as AI and blockchain to make homebuilding accessible to all. <a href="/documents/HomeQube Brief 1.0 [with Whitepaper].pdf" class="blog-link" target="_block"> Read our whitepaper </a> to know our mission, vision, and what drives us forward to serve all the stakeholders in the industry. 
                                        </i>
                                        <br/>
                                        <br/>
                             

                                         Published on April 2022
                              
                                    </p>

                                 
                                    <p class="carbon-text-blog">
                                        Share
                                        <br/>
                                    
                                        <a href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/The_future_of_the_built_environment.html" target="_blank" rel="noreferrer noopener pt-3" class="flink-icon">
                                            <img class="blog-icon me-3" src={twitter} alt=""/>
                                        </a>
                                    </p>                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default TheFutureOfBuiltEnivorment