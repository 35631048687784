// components/PageNotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import pagenotfound from "../images/page_not_found.png"

const PageNotFound = () => {
  return (
    <div className="vh-100 w-100 d-flex align-items-center justify-content-center bg-dark">
      <div className="text-center d-flex-col align-items-center justify-content-center">
        <div className="errorpage-img">
          <img src={pagenotfound} className="img-fluid mb-4" alt="404 Error" />
        </div>
        <h1 className="display-4 mb-3 text-white">Page not found</h1>
        <p className="lead text-white">We're sorry, the page you have requested cannot be found.</p>
        <Link to="/" className="btn btn-outline-light">
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;