import React from "react";
// import qubelogo from "../../images/Home/hqlogo-with-tag.png"

function NewGenSection() {
  return (
    <>
      <section className="newgen-section text-light p-5 text-center text-sm-start" >
        <div className="container-bg container h-100 d-flex flex-column align-content-center justify-content-center pb-5">
          <div className="d-flex flex-column align-content-center justify-content-between container-content">
            {/* <div className="comp-logo-display d-flex align-content-center justify-content-center">
              <img src={qubelogo} alt="QUBE" />
            </div> */}
            <div className="hero-banner-container">
              <div>
                <h1 className="newgen-quote mb-5">
                    “Sustainability involves activities such as reducing waste, using renewable energy sources, 
                    and finding ways to conserve resources; on the other hand, regeneration goes further, creating 
                    systems that work with nature to restore damaged ecosystems.”
                    <br />
                    - anonymous
                </h1>
                <h1 className="newgen-title">
                    HOMEQUBE, your Real-world assets platform for:
                </h1>
                <div className="newgen-items-container pb-5">
                  <div className="newgen-col">
                    <p className="newgen-item-title">
                      New gen internet
                    </p>
                    <p className="newgen-item-text">
                      Web3
                    </p>
                  </div>
                  <div className="newgen-col">
                    <p className="newgen-item-title">
                      New gen materials
                    </p>
                    <p className="newgen-item-text">
                      Pre-engineered buildings, lightweight materials
                    </p>
                  </div>
                  <div className="newgen-col">
                    <p className="newgen-item-title">
                      New gen Everything!
                    </p>
                    <p className="newgen-item-text">
                      Committed to continuous improvement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewGenSection;
