import photorelease from "../src/images/NewImages/newBlog/News1.svg";

import jpcalma from "../src/images/Blog/01_JP_Calma.jpg";

const newsData = {
  cardData: [
    {
      id: 1,
      img: photorelease,
      title: "Homeqube Is New AI-Powered Homebuilding App Empowering Users, Industry Players",
      desc: " March 2022 -- On the heels of the COVID-19 pandemic and in the face of accelerated climate change, a startup with roots in the construction industry has launched a mobile app that makes homebuilding accessible to all.",
      link: "/news/homeqube-is-new-ai",
    },
    {
      id: 2,
      img: jpcalma,
      title: "A Calma Creates Next-Gen Architecture, Homebuilding Ecosystem on Web3",
      desc: "",
      link: "/news/next-gen-architecture",
    },
  ],
};
export default newsData;
