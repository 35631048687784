import React from 'react'
import FaqsContent from '../components/faqs/FaqsContent'
import FaqsFootbar from '../components/faqs/FaqsFootbar'
import FaqsHeader from '../components/faqs/FaqsHeader'

function Faqs() {
  return (
    <>
        <FaqsHeader/>
        <FaqsContent/>
        <FaqsFootbar/>
    </>
  )
}

export default Faqs