import React from "react";
import line from "../../images/NewImages/horizontal-line.svg";
import qube from "../../images/NewImages/state-qube.svg";

function StateofPlayContent() {
  return (
    <>
      <section className="showcase text-light p-5 py-5 ">
        <div className="beliefs-bg">
          <div className="container">
            <div className="container p-5">
              <div className="state-of-play-header px-5 mx-5">
                THE PLATFORM IS COMPRISED OF AN ENCOMPASSING QUAD
              </div>
              <p className="state-of-play-paragraph px-5 mx-5">
                HomeQube’s system works through the quadrivium of: [1] a Peer to
                Peer (P2P) Marketplace for supplies, [2] a dAPP for work process
                integrations, [3] an NFT Marketplace for ownership
                opportunities, and [4] a DeFi Portal for real estate finance.
              </p>
            </div>
          </div>

          <div className="container p-5 d-flex justify-content-center">
            <img src={line} alt="" />
          </div>
          <div className="container p-5 d-flex justify-content-center">
            <img src={qube} alt="" />
          </div>
          <div className="container">
            <div className="container p-5">
              <div className="state-of-play-header-2 px-5 mx-5">
                P2P Marketplace
              </div>
              <p className="state-of-play-paragraph-2 px-5 mx-5">
                The <span className="qube-wrap">Peer to Peer</span> Marketplace
                provides a decentralized avenue for supplies. It is composed of
                Smart Contracts that supply, deliver, install, and manufacture
                construction materials.
                {/* A
                <span className="qube-wrap">P2P</span> marketplace that is
                decentralized and composed of Smart Contracts for E-Shops that
                supply, deliver, install, and manufacture, construction
                materials. */}
              </p>
            </div>
          </div>

          <div className="container p-5 d-flex justify-content-center">
            <img src={line} alt="" />
          </div>
          <div className="container p-5 d-flex justify-content-center">
            <img src={qube} alt="" />
          </div>
          <div className="container">
            <div className="container p-5">
              <div className="state-of-play-header-2 px-5 mx-5">dAPP</div>
              <p className="state-of-play-paragraph-2 px-5 mx-5">
                The <span className="qube-wrap">dAPP</span> integrates the work
                process of User Data (Land and Condo) with open metaverse
                collaboration. This tokenizes the system/process across
                stakeholder needs.
                {/* A
                <span className="qube-wrap">dAPP</span> that begins with User
                Data (Land and Condo) with open metaverse collaboration,
                tokenized work processes across stakeholder needs. */}
              </p>
            </div>
          </div>

          <div className="container p-5 d-flex justify-content-center">
            <img src={line} alt="" />
          </div>
          <div className="container p-5 d-flex justify-content-center">
            <img src={qube} alt="" />
          </div>
          <div className="container">
            <div className="container p-5">
              <div className="state-of-play-header-2 px-5 mx-5">
                NFT Marketplace
              </div>
              <p className="state-of-play-paragraph-2 px-5 mx-5">
                Ownership opportunities can be attained in the{" "}
                <span className="qube-wrap">NFT Marketplace</span>. The space
                offers products such as Generative Design, System Architecture
                Products, Prefabricated Volumetric Units, and 3D Printed Parts.
                {/* An
                <span className="qube-wrap">NFT Marketplace</span> for
                Parametric Products such as Generative Design, System
                Architecture Products, Pre-fabricated Volumetric Units, 3D
                Printed Parts, including Real Estate Investment Offerings
                through our DAO Projects. */}
              </p>
            </div>
          </div>

          <div className="container p-5 d-flex justify-content-center">
            <img src={line} alt="" />
          </div>
          <div className="container p-5 d-flex justify-content-center">
            <img src={qube} alt="" />
          </div>
          <div className="container">
            <div className="container p-5 mb-5">
              <div className="state-of-play-header-2 px-5 mx-5">
                DeFi Real Estate Portal
              </div>
              <p className="state-of-play-paragraph-2 px-5 mx-5 mb-5">
                DeFi is an offering of{" "}
                <span className="qube-wrap">Real Estate Finance</span>. Property
                investment in the community is offered via our DAO Projects.
                {/* A{" "}
                <span className="qube-wrap">DeFi Real Estate Portal</span> DeFi
                Real Estate Portal for Property Investment Community Offering:
                DAO Projects. */}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StateofPlayContent;
