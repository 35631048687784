import React from "react";
import instagram from "../../images/NewImages/footer/newInsta.svg";
import twitter from "../../images/NewImages/footer/Twitter_X.png";
import linkedin from "../../images/NewImages/footer/newLinked.svg";
import telegram from "../../images/NewImages/footer/newTelegram.svg";
import tiktok from "../../images/NewImages/footer/tiktok.png";
import youtube from "../../images/NewImages/footer/yt.png";
import fb from "../../images/NewImages/footer/fb-icon.png";

function Footer3() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="footer mt-auto py-2 bg-light fixed-bottom bg-black text-white">
        <div className="container-footer3">
            <div class="footer3-left">
              <p>@{currentYear} HOMEQUBE PTE. LTD.</p>
              <a href="https://www.shop.homeqube.com/buyer/about" target="_blank" rel="noreferrer noopener"><p>Disclaimer &#8226;</p></a>
              <a href="/terms-of-use"><p>Terms of Use &#8226;</p></a>
              <a href="/privacy-policy"><p>Privacy Policy</p></a>
            </div>
            <div class="footer3-left-responsive">
              <p>@{currentYear} HOMEQUBE PTE. LTD.</p>
              <a href="https://www.shop.homeqube.com/buyer/about" target="_blank" rel="noreferrer noopener"><p>Disclaimer</p></a>
              <a href="/terms-of-use"><p>Terms of Use</p></a>
              <a href="/privacy-policy"><p>Privacy Policy</p></a>
            </div>
            <div className="d-flex pb-1">
              <a href="https://www.instagram.com/homeqube.ai/" target="_blank" rel="noreferrer noopener" className="a-no-design" >
                <img src={instagram} alt="ig" className="me-3" />
              </a>
              <a href="https://twitter.com/QubeToken" target="_blank" rel="noreferrer noopener" className="a-no-design" >
                <img src={twitter} alt="tw" className="me-3" />
              </a>
              <a href="https://www.facebook.com/QUBETOKEN" target="_blank" rel="noreferrer noopener" className="a-no-design" >
                <img src={fb} alt="fb" className="me-3" />
              </a>
              <a href="https://www.linkedin.com/company/homeqube-pte-ltd/" target="_blank" rel="noreferrer noopener" className="a-no-design" >
                <img src={linkedin} alt="li" className="me-3" />
              </a>
              <a href="https://www.instagram.com/homeqube.ai/" target="_blank" rel="noreferrer noopener" className="a-no-design" >
                <img src={telegram} alt="tg" className="me-3" />
              </a>
              <a href="https://www.tiktok.com/@homeqube" target="_blank" rel="noreferrer noopener" className="a-no-design" >
                <img src={tiktok} alt="tk" className="me-3" />
              </a>
              <a href="https://www.youtube.com/@HOMEQUBE" target="_blank" rel="noreferrer noopener" className="a-no-design" >
                <img src={youtube} alt="yt" className="me-3" />
              </a>
            </div>
        </div>
      </footer>
    </>
  );
}

export default Footer3;
