import React from "react";
import StateofPlayContent from "../modules/stateofplay/StateofPlayContent";
import StateofPlayFootbar from "../modules/stateofplay/StateofPlayFootbar";
import NewBlogv2 from "./NewBlogv2";
import NewNews from "./newNews";

function StateofPlay() {
  return (
    <>
      <StateofPlayContent />
      <NewBlogv2/> 
      <NewNews/>
      <StateofPlayFootbar/>
    </>
  );
}

export default StateofPlay;
