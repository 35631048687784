import React from 'react'
import cagbalete from "../../images/NewImages/DAO/Cagbalete.svg";
import rightArrow from "../../images/NewImages/Arrow right.svg";

function DaoProjectsContentResponsive() { 
  return (
    <>
    <section className="bg-black pb-5 dao-responsive">
      <div className="container">
        <div className="container p-5">
          <div class="state-of-play-header px-5 mx-5">DAO PROJECTS</div>
        </div>
        <div className="pb-5">
          <div className="container">
            <div className="dao-content-title mb-4">CAGBALETE SAND CLUSTERS</div>
            <div className="dao-content-sub-title mb-4">
              LOCATION: CAGBALETE, QUEZON
            </div>
            <img src={cagbalete} alt="" className="img-fluid mb-3" />
            <div className="dao-content mb-5">
              Cagbalete Sand Clusters consists of prefabricated hyperbolic
              structures TThe Cagbalete Sand Clusters by Carlo Calma
              Consultancy combines the ideals of sustainability to create a
              new typology for eco-tourism on an island in the Philippines.
              Biomimetic architecture takes inspiration from construction
              principles found in the natural world. From the natural
              environment to the functional ergonomics of various species,
              technological exploration has been developing hand-in-hand with
              explorations in form-making. The term biomimetic architecture
              was popularised by Janine Benyus in her 1997 book Biomimicry:
              Innovation Inspired by Nature. There has been a correlation
              between biomimicry and the idea of sustainable architecture
              since its popularisation, a fact that is also highlighted in the
              book.
            </div>
            <div className="dao-learn-more">
              <a
                class="a-no-design"
                href="HTTPS://WWW.STIRWORLD.COM/SEE-FEATURES-CAGBALETE-SAND-CLUSTERS-CONSISTS-OF-PREFABRICATED-HYPERBOLIC-STRUCTURES"
              >
                Learn More <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default DaoProjectsContentResponsive