import React from 'react'
import worldmap from "../../images/Home/world 1.jpg"

function Roadmap() {
  return (
    <>
    <section class="p-5 text-center content-eco1-bg">
        <div class="ai-header">
            <p class="ai-title">
                Homeqube Roadmap
            </p>
            <br/>
                <center>
            {/* <div class="home-rectangle"></div> */}
            </center>
            
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <img src={worldmap} class="img-fluid worldmap1" alt="" />
        {/* <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div class="timeline-steps aos-init aos-animate text-uppercase" data-aos="fade-up">
            <div class="timeline-step">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover">
                    <div class="inner-circle"></div>
                    <p class="roadmap-header-h3">2022 Conceptual</p>
                    <div class="roadmap-list1">
                        <ul class="list-group">
                            <li class="list-group-item border-0 list-active-group">Team assemble</li>
                            <li class="list-group-item border-0 list-active-group">App conceptualization</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="timeline-step">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover">
                    <div class="inner-circle"></div>
                    <p class="roadmap-header-h3">2021 Awareness</p>
                    <div class="roadmap-list1">
                        <ul class="list-group">
                            <li class="list-group-item border-0 list-active-group">Corporate Website</li>                      
                            <li class="list-group-item border-0 list-active-group">Whitepaper 1.0</li>
                            <li class="list-group-item border-0 list-active-group">Social Media Build up</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="timeline-step">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover">
                    <div class="inner-circle"></div>
                    <p class="roadmap-header-h3">2022 Public Betas</p>
                    <div class="roadmap-list1">
                        <ul class="list-group">
                            <li class="list-group-item border-0">Homeqube.io</li>
                            <li class="list-group-item border-0">Homeqube.ai</li>
                            <li class="list-group-item border-0">Homeqube.com</li>
                            <li class="list-group-item border-0">Ecosystem Fund</li>
                            <li class="list-group-item border-0">NFT Token Sales</li>
                            <li class="list-group-item border-0">Public Mock-up</li>
                            <li class="list-group-item border-0">Whitepaper 2.0</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="timeline-step">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover">
                    <div class="inner-circle"></div>
                    <p class="roadmap-header-h3">2023 MVP MATURITY</p>
                    <div class="roadmap-list1">
                        <ul class="list-group">
                            <li class="list-group-item border-0">Lead Investment</li>
                            <li class="list-group-item border-0">Private Sale</li>
                            <li class="list-group-item border-0">DeFi Real Estate</li>
                            <li class="list-group-item border-0">ICO/IDO</li>
                            <li class="list-group-item border-0">Global Planning</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="timeline-step mb-0">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover">
                    <div class="inner-circle"></div>
                    <p class="roadmap-header-h3">Social Developments</p>
                    <div class="roadmap-list1">
                        <ul class="list-group">
                            <li class="list-group-item border-0">Carbon credit Issurances</li>
                            <li class="list-group-item border-0">Future Rounds</li>
                        </ul>
                    </div>
                </div>
            </div>
            </div> */}
        </section>
    </>
  )
}

export default Roadmap