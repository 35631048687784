import React from "react";

function FaqsHeader() {
  return (
    <>
      <section class="pt-5 text-center">
        <br />
        <br />
        <br />
        <p class="faq-title"> Frequently asked questions</p>
        <p class="q-tokenomics-showcase-intro-lead p-3 text-uppercase">
          Know more about home designing and building on the app, QUBE tokens,
          <br /> products, or how to work with Homeqube as an industry pro.
        </p>
        <center>
          <div class="faq-rectangle"></div>
          <br />
          <br />
          <br />
          <br />
          <br />
        </center>
      </section>
    </>
  );
}

export default FaqsHeader;
