import React from "react";
import twitter from "../../images/Icons/twitter.svg";
import photorelease from "../../images/Home/photoRelease.jpg";

function HqIsNewAi() {
  return (
    <>
      <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
          <div class="container p-3">
            <div class="carbon-content-container-bg">
              <div class="d-sm-flex align-items-center justify-content-between">
                <div class="container-sm">
                  <h3 class="carbon-benefits-content">
                    Homeqube Is New AI-Powered Homebuilding App Empowering
                    Users, Industry Players
                  </h3>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/News/homeqube_is_new_ai_powered.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img class="blog-icon me-3" src={twitter} alt="" />
                    </a>
                  </p>

                  <p class="carbon-text ">
                    <br />
                    <br />
                    March 2022 -- On the heels of the COVID-19 pandemic and in
                    the face of accelerated climate change, a startup with roots
                    in the construction industry has launched a mobile app that
                    makes homebuilding accessible to all.
                    <br />
                    <br />
                    Homeqube, powered by artificial intelligence (AI) and
                    blockchain technology, seeks to empower users to build their
                    future homes through its built-in network of architects,
                    engineers, and contractors as well as manufacturers. Anyone
                    with internet access can use the app, whether or not they
                    are in the actual process of buying or building their home,
                    and start designing their dream abode.
                    <br />
                    <br />
                    Homeqube is founded by Jose Paolo Calma, the CEO of
                    Multi-Development and Construction Corporation (MDCC), a
                    pioneer of interior construction in the Philippines founded
                    in 1975. His involvement in the family business exposed him
                    to the built environment and its complex needs, prompting
                    him to dream up a decentralized online platform for
                    homebuilding accessible to all.
                    <br />
                    <br />
                    Homeqube solves many of the recurring problems in the
                    homebuilding industry, according to Calma. These include
                    long lead times, lack of publicly accessed knowledge and
                    transparency in architecture and engineering processes, lack
                    of direct links to manufacturers and e-commerce, high carbon
                    emissions from current materials, and inefficiencies in
                    skilled labor and design processes.
                    <br />
                    <br />
                    “With the worst modern global pandemic bringing about
                    far-reaching mobility and labor challenges, a home building
                    process that’s lagging behind can only bring about more
                    shortage in the longer term,” Calma says.
                    <br />
                    <br />
                    The app provides information on the cost, mobility, and
                    dynamics of the home construction process, helping users to
                    make informed home design and building decisions. Some key
                    features include optical character recognition (OCR) to
                    automatically plot their lot area, agile design using
                    “knobs” or user-centric controls, and automatic generation
                    of essential documents before move-in.
                    <br />
                    <br />
                    At the heart of the app are these knobs for building houses.
                    These are based on a deconstruction philosophy where various
                    combinations of designs can be produced from the basic parts
                    of the systems, and turning the knobs affect the cost,
                    lifestyle, and mobility metrics. The freemium version of the
                    app provides users with the first set of knobs.
                    <br />
                    <br />
                    Users can also choose to engage by playing games in the app.
                    Through QUBE tokens and gamification, users can design and
                    earn tokens, join game challenges, or eventually sell their
                    house design as non-fungible tokens (NFTs) once they become
                    community members.
                    <br />
                    <br />
                    Industry professionals in diverse areas of manufacturing,
                    engineering, fabrication, plumbing, interior design, and
                    more can enrich their practice or kickstart their business
                    at Homeqube. They can engage in the app in various ways,
                    including 3D parts printing without the need for a factory
                    and joining the app’s formal manufacturing community to
                    showcase their products in the app.
                    <br />
                    <br />
                    Committed to harnessing blockchain technology, Homeqube has
                    selected Solana as its web-scale blockchain of choice,
                    deeming it the most efficient and low-energy blockchain
                    technology by far.
                    <br />
                    <br />
                    Learn more about the app by visiting qube.homeqube.com.
                  </p>

                  <h3 class="carbon-benefits-content">Photo Release</h3>

                  <img class="news-imgPR" src={photorelease} alt="" />

                  <br />
                  <br />
                  <h3 class="carbon-benefits-content">About Homeqube</h3>

                  <p class="carbon-text ">
                    Homeqube is a homebuilding app powered by AI and blockchain
                    technology, enabling users to build their future home
                    through a built-in network of architecture, engineering, and
                    construction (AEC) experts and manufacturing agents on
                    Web3.0, empowering the entire industry at scale. It promotes
                    fast, quality, sustainable homebuilding processes,
                    demystifying residential construction and promoting
                    innovation, speed to market, and participation in the
                    digital economy and forming an empowered community of
                    homebuilders.
                  </p>

                  <h3 class="carbon-benefits-content">Contact Information</h3>

                  <p class="carbon-text ">
                    Homeqube Communication Group
                    <br />
                    Email: about@homeqube.com
                    <br />
                    Website: qube.homeqube.com
                    <br />
                    seed.homeqube.com
                    <br />
                    www.homeqube.ai
                    <br />
                  </p>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/News/homeqube_is_new_ai_powered.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img class="blog-icon me-3" src={twitter} alt="" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HqIsNewAi;
