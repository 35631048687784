import React from "react";

//press
import ManilaBulletin from "../../images/Home/press/Manila-bulletin.png"
import BusinessMirror from "../../images/Home/press/Business-mirror.png"
import BusinessWorld from "../../images/Home/press/Business-world.png"
import PhilippineDailyInquirer from "../../images/Home/press/Philippine-daily-inquirer.svg"
import ManilaStandard from "../../images/Home/press/Manila-standard.png"
import PhilippineStar from "../../images/Home/press/Phil-star.webp"
import ManilaTimes from "../../images/Home/press/Manila-times.png"
import DailyTribune from "../../images/Home/press/Daily-tribune.png"
import Malaya from "../../images/Home/press/Malaya.png"
import Sunstar from "../../images/Home/press/Sunstar.png"
// import AbsCbn from "../../images/Home/press/Abs-cbn.png"
// import Gma from "../../images/Home/press/Gma-online.png"
// import Rappler from "../../images/Home/press/Rappler.png"
import JournalOnline from "../../images/Home/press/Journal-online.png"
import cnnph from "../../images/Home/press/Cnn-ph.png"
import pna from "../../images/Home/press/Pna-logo.png"
import Forkast from "../../images/Home/press/Forkast.png"
import Coindesk from "../../images/Home/press/Coin-desk.svg"
import Cointelegraph from "../../images/Home/press/Coin-telegraph.svg"
import ancnews from "../../images/Home/press/Anc-news.png"
import BitPinas from "../../images/Home/press/Bit-pinas.png"
import Tatlerph from "../../images/Home/press/Tatler-ph.png"
import LifestyleAsia from "../../images/Home/press/Lifestyle-asia.png"
import PeopleAsia from "../../images/Home/press/People-asia.png"

//tech
import Newsbytes from "../../images/Home/press/Newsbytes.png"
import Backendnews from "../../images/Home/press/Backendnews.png"
// import Iconexecutiveasia from "../../images/Home/press/Iconexecutiveasia.png"
// import philbiznews from "../../images/Home/press/philbiznews-logo.png"
// import Scitechdigitalnews from "../../images/Home/press/Scitechdigitalnews.png"
import Techbeatph from "../../images/Home/press/Techbeatph.png"
// import Bilyonaryo from "../../images/Home/press/Bilyonaryo.png"
import Orange from "../../images/Home/press/Orange-magazine.png"
// import Megabites from "../../images/Home/press/Megabites.png"
import Lionheartv from "../../images/Home/press/Lionheartv.png"
// import Digitalfilipina from "../../images/Home/press/Digitalfilipina.png"
import Nextgenday from "../../images/Home/press/Nextgenday.png"
import Philitorg from "../../images/Home/press/Philitorg.png"
import headtopics from "../../images/Home/press/headtopics.svg"
import verifiednewsexplorernetwork from "../../images/Home/press/verifiednewsexplorernetwork.svg"

//partners
import proptechforgoodlogo2 from "../../images/Home/press/proptechforgoodlogo2.png"
import proptechinstiturewhitelogo from "../../images/Home/press/proptechinstiture-white_logo.jpg"
import proptechlogo from "../../images/Home/press/proptechlogo.png"
import uli from "../../images/Home/press/uli-logo.png"
import basingapore from "../../images/Home/press/ba-singapore.png"
import sfalogo from "../../images/Home/press/sfalogo-white.png"
import dwic from "../../images/Home/press/dwic.png"
import usproptechcouncil from "../../images/Home/press/us_proptech_council_logo.png"

function Companies() {
  
  const presscontent = [
    {
      logo: <img src={ManilaBulletin} alt='img' />,
      link: "",
    },
    {
      logo: <img src={PhilippineDailyInquirer} alt='img' />,
      link: "",
    },
    {
      logo: <img src={BusinessMirror} alt='img' />,
      link: "",
    },
    {
      logo: <img src={BusinessWorld} alt='img' />,
      link: "",
    },
    {
      logo: <img src={ManilaStandard} alt='img' />,
      link: "",
    },
    {
      logo: <img src={PhilippineStar} alt='img' />,
      link: "",
    },
    {
      logo: <img src={ManilaTimes} alt='img' />,
      link: "",
    },
    {
      logo: <img src={DailyTribune} alt='img' />,
      link: "",
    },
    {
      logo: <img src={Malaya} alt='img' />,
      link: "",
    },
    {
      logo: <img src={Sunstar} alt='img' />,
      link: "",
    },
    // {
    //   logo: <img src={AbsCbn} alt='img' />,
    //   link: "",
    // },
    // {
    //   logo: <img src={Gma} alt='img' />,
    //   link: "",
    // },
    // {
    //   logo: <img src={Rappler} alt='img' />,
    //   link: "",
    // },
    {
      logo: <img src={JournalOnline} alt='img' />,
      link: "",
    },
    {
      logo: <img src={cnnph} alt='img' />,
      link: "",
    },
    {
      logo: <img src={pna} alt='img' />,
      link: "",
    },
    {
      logo: <img src={BitPinas} alt='img' />,
      link: "",
    },
    {
      logo: <img src={Forkast} alt='img' />,
      link: "",
    },
    {
      logo: <img src={Coindesk} alt='img' />,
      link: "",
    },
    {
      logo: <img src={Cointelegraph} alt='img' />,
      link: "",
    },
    {
      logo: <img src={ancnews} alt='img' />,
      link: "",
    },
    {
      logo: <img src={Tatlerph} alt='img' />,
      link: "",
    },
    {
      logo: <img src={LifestyleAsia} alt='img' />,
      link: "",
    },
    {
      logo: <img src={PeopleAsia} alt='img' />,
      link: "",
    },
  ]

  const techbloggerists = [
    {
      id: 1,
      logoz: <img src={Newsbytes} alt='img' />,
      link: "",
    },
    {
      logoz: <img src={Backendnews} alt='img' />,
      link: "",
    },
    // {
    //   logoz: <img src={Iconexecutiveasia} alt='img' />,
    //   link: "",
    // },
    // {
    //   logoz: <img src={philbiznews} alt='img' />,
    //   linkz: "",
    // },
    // {
    //   logoz: <img src={Scitechdigitalnews} alt='img' />,
    //   link: "",
    // },
    {
      logoz: <img src={Techbeatph} alt='img' />,
      link: "",
    },
    // {
    //   logoz: <img src={Bilyonaryo} alt='img' />,
    //   link: "",
    // },
    {
      logoz: <img src={Orange} alt='img' />,
      link: "",
    },
    // {
    //   logoz: <img src={Megabites} alt='img' />,
    //   link: "",
    // },
    {
      logoz: <img src={Lionheartv} alt='img' />,
      link: "",
    },
    // {
    //   logoz: <img src={Digitalfilipina} alt='img' />,
    //   linkz: "",
    // },
    {
      logoz: <img src={Nextgenday} alt='img' />,
      link: "",
    },
    {
      logoz: <img src={Philitorg} alt='img' />,
      link: "",
    },
    {
      logoz: <img src={headtopics} alt='img' />,
      link: "",
    },
    {
      logoz: <img src={verifiednewsexplorernetwork} alt='img' />,
      link: "",
    },

    
  ]

  return (
    <>
      <section className="companies text-light p-5 text-center" >
        <div className="companies-content-container container">
          <div className="d-flex flex-column justify-content-center align-items-center companies-content-flex">
            <div className="companies-title-container pb-5">
              <h5 class="companies-title">
                Media Partners
              </h5>
              <p className="companies-sub-title">
                As featured on the following media channels
              </p>
            </div>
            <div class="companies-flex-container">
              <div class="companies-inner-flex">
                <div class="press-slider">
                  {presscontent.map(press => (
                    <div class="press-content">
                      {press.logo}
                    </div>
                  ))}
                </div>
                <div class="press-slider">
                  {presscontent.map(press => (
                    <div class="press-content">
                      {press.logo}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div class="companies-flex-container">
              <div class="companies-inner-flex-two">
                <div class="press-slider-two">
                  {techbloggerists.map(techblogger => (
                    <div class="press-content-two">
                      {techblogger.logoz}
                    </div>
                  ))}
                </div>
                <div class="press-slider-two">
                  {techbloggerists.map(techblogger => (
                    <div class="press-content-two">
                      {techblogger.logoz}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="companies-title-container pb-5 pt-5">
              <h5 class="companies-title">
                Our Industry Partners in Building Your Future
              </h5>
            </div>
            <div className="companies-partners-container">
              <div className="companies-partners-flex">
                  <div className="companies-partners">
                    <img src={uli} alt="" />
                  </div>
                  <div className="companies-partners">
                    <img src={proptechlogo} alt="" />
                  </div>
                  <div className="companies-partners">
                    <img src={proptechforgoodlogo2} alt="" />
                  </div>
                  <div className="companies-partners">
                    <img src={proptechinstiturewhitelogo} alt="" />
                  </div>
                  <div className="companies-partners">
                    <img src={sfalogo} alt="" />
                  </div>
                  <div className="companies-partners">
                    <img src={basingapore} alt="" />
                  </div>
                  <div className="companies-partners">
                    <img src={dwic} alt="" />
                  </div>
                  <div className="companies-partners">
                    <img src={usproptechcouncil} alt="" />
                  </div>
                  
              </div>
            </div>      
          </div>
        </div>
      </section>
    </>
  );
}

export default Companies;
