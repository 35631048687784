import React from 'react';

function BentoButton({ isOpen, onClick }) {

  return (
    <button className="site-nav" onClick={onClick}>
      <svg
        width='53'
        height='48'
        viewBox='0 0 148 153'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M74.0003 102C80.7837 102 86.3337 107.737 86.3337 114.75C86.3337 121.763 80.7837 127.5 74.0003 127.5C67.217 127.5 61.667 121.763 61.667 114.75C61.667 107.737 67.217 102 74.0003 102ZM74.0003 63.75C80.7837 63.75 86.3337 69.4875 86.3337 76.5C86.3337 83.5125 80.7837 89.25 74.0003 89.25C67.217 89.25 61.667 83.5125 61.667 76.5C61.667 69.4875 67.217 63.75 74.0003 63.75ZM74.0003 25.5C80.7837 25.5 86.3337 31.2375 86.3337 38.25C86.3337 45.2625 80.7837 51 74.0003 51C67.217 51 61.667 45.2625 61.667 38.25C61.667 31.2375 67.217 25.5 74.0003 25.5ZM37.0003 102C43.7837 102 49.3337 107.737 49.3337 114.75C49.3337 121.763 43.7837 127.5 37.0003 127.5C30.217 127.5 24.667 121.763 24.667 114.75C24.667 107.737 30.217 102 37.0003 102ZM37.0003 63.75C43.7837 63.75 49.3337 69.4875 49.3337 76.5C49.3337 83.5125 43.7837 89.25 37.0003 89.25C30.217 89.25 24.667 83.5125 24.667 76.5C24.667 69.4875 30.217 63.75 37.0003 63.75ZM37.0003 25.5C43.7837 25.5 49.3337 31.2375 49.3337 38.25C49.3337 45.2625 43.7837 51 37.0003 51C30.217 51 24.667 45.2625 24.667 38.25C24.667 31.2375 30.217 25.5 37.0003 25.5ZM111 102C117.784 102 123.334 107.737 123.334 114.75C123.334 121.763 117.784 127.5 111 127.5C104.217 127.5 98.667 121.763 98.667 114.75C98.667 107.737 104.217 102 111 102ZM111 63.75C117.784 63.75 123.334 69.4875 123.334 76.5C123.334 83.5125 117.784 89.25 111 89.25C104.217 89.25 98.667 83.5125 98.667 76.5C98.667 69.4875 104.217 63.75 111 63.75ZM111 25.5C117.784 25.5 123.334 31.2375 123.334 38.25C123.334 45.2625 117.784 51 111 51C104.217 51 98.667 45.2625 98.667 38.25C98.667 31.2375 104.217 25.5 111 25.5Z'
          fill='white'
        />
      </svg>
    </button>
  );
}

export default BentoButton;
