import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useRef } from "react";
import logo from "../images/NewImages/hq_logo_sub.png";
import { NavLink } from "react-router-dom";
// import rightArrow from "../images/NewImages/Arrow right.svg";
// import dropdownArrow from "../images/NewImages/Angle down.svg";
import Nav from "react-bootstrap/Nav";
import ClickablePopover from "./BentoNav";

function NewNavbar() {
  const navRef = useRef();

  // commented this 3/21/24
  // const [walletAddress, setWalletAddress] = useState(null);
  // const checkIfWalletIsConnected = async () => {
  //   // We're using optional chaining (question mark) to check if the object is null
  //   if (window?.solana?.isPhantom) {
  //     console.log("Phantom wallet found!");
  //     const response = await window.solana.connect({ onlyIfTrusted: true });
  //     console.log("Connected with Public Key:", response.publicKey.toString());

  //     setWalletAddress(response.publicKey.toString());
  //   } else {
  //     alert("Solana object not found! Get a Phantom Wallet 👻");
  //   }
  // };

  /*
   * Let's define this method so our code doesn't break.
   * We will write the logic for this next!
   */

  // commented this 3/21/24
  // const connectWallet = async () => {
  //   const { solana } = window;
  //   if (solana) {
  //     const response = await solana.connect();
  //     console.log("Connected with Public Key:", response.publicKey.toString());
  //     setWalletAddress(response.publicKey.toString());
  //   }
  // };
  // const renderNotConnectedContainer = () => (
  //   <button
  //     className="new-white-button btn btn-lg rounded-pill"
  //     onClick={connectWallet}
  //   >
  //     Connect Wallet
  //   </button>
  // );

  /*
   * We want to render this UI when the user hasn't connected
   * their wallet to our app yet.
   */

  /*
   * When our component first mounts, let's check to see if we have a connected
   * Phantom Wallet
   */

  // commented this 3/21/24
  // useEffect(() => {
  //   const onLoad = async () => {
  //     await checkIfWalletIsConnected();
  //   };
  //   window.addEventListener("load", onLoad);
  //   return () => window.removeEventListener("load", onLoad);
  // }, []);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive-nav");
  };

  const hideNavbar = () => {
    navRef.current.classList.remove("responsive-nav");
  };
  return (
    <>
      <header className="new-header navbar-expand-lg">
        <div className="new-header-container d-flex justify-content-between align-items-center">
          <NavLink to="/" className="navbar-brand">
            <img class="logo" src={logo} alt="" height={42} width={185} />
          </NavLink>
          <Nav className="new-nav d-flex" ref={navRef} sticky="top">
            <NavLink
              to="/"
              className="navbar-brand reponsive-logo"
              onClick={hideNavbar}
            >
              <img class="logo" src={logo} alt="" height={42} width={185} />
            </NavLink>
            {/* <NavLink
              to="/state-of-play"
              className="new-nav-links"
              activeClassName="active"
              onClick={hideNavbar}
            >
              <p class="nav-item-pipehome pe-3 pt-3 mt-1">state of play</p>
            </NavLink>
            <NavLink
              className="new-nav-links"
              activeClassName="active"
              to="/qube-tokenomics"
              onClick={hideNavbar}
            >
              <p class="nav-item-pipehome pe-3 pt-3 mt-1">tokenomics</p>
            </NavLink> */}
            {/* commented this 3/21/24 */}
            {/* <NavLink
              className="new-nav-links"
              activeClassName="active"
              to="/philosophy"
              onClick={hideNavbar}
            >
              <p class="nav-item-pipehome pt-3 mt-1">our philosophy</p>
            </NavLink> */}

            {/* <span class="pt-2 mt-1 nav-item ps-4 dropdown text-white">
              <div class="dropdown">
                <span
                  class="nav-link a-link nav-item-pipehome"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  our products{" "}
                  <img
                    src={dropdownArrow}
                    alt=""
                    className="new-dropdown-arrow ms-1 mb-1"
                  />
                </span>
                <ul
                  class="dropdown-menu text-start"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li className="mb-2">
                    <a class="dropdown-item" href="https://www.homeqube.io/">
                    IO (BETA) <img src={rightArrow} alt="" />
                    </a>
                  </li>
                  <li className="mb-2">
                    <a class="dropdown-item" href="https://www.homeqube.ai/">
                      AI (BETA) <img src={rightArrow} alt="" />
                    </a>
                  </li>
                  <li className="mb-2">
                    <a class="dropdown-item" href="https://www.homeqube.com/">
                      COM (MVP SOON) <img src={rightArrow} alt="" />
                    </a>
                  </li>
                  <li className="mb-2">
                    <a class="dropdown-item" href="/DAO-projects">
                      DAO (POC)
                    </a>
                  </li>
                </ul>
              </div>
            </span> */}
            <div className="nav-new-buttons-responsive">
              <span class="nav-item text-center new-nav-btn1">
                {/* <Link
                  to="/documents/HomeQube Brief 1.0 [with Whitepaper].pdf"
                  target="_blank"
                  role="button"
                  download
                >
                  <button
                    class="new-black-button btn btn-lg rounded-pill"
                    type="button"
                  >
                   Whitepaper
                  </button>
                </Link> */}
                <a href="https://homeqube.gitbook.io/homeqube/" target="_blank" rel="noopener noreferrer">
                  <button class="new-black-button btn btn-lg rounded-pill" type="button">
                    How to Docs
                  </button>
                </a>
              </span>

              {/* <span class="nav-item text-center  mx-3">
                {!walletAddress && renderNotConnectedContainer()}
              </span> */}
            </div>
            <button
              className="new-nav-btn new-nav-close-btn"
              onClick={showNavbar}
            >
              <FaTimes />
            </button>
          </Nav>
          <div className="nav-new-buttons">
            <span class="nav-item text-center new-nav-btn1 mx-3">
              {/* <Link
                to="/documents/HomeQube Brief 1.0 [with Whitepaper].pdf"
                target="_blank"
                role="button"
                download
              >
                <button
                  class="new-black-button btn btn-lg rounded-pill"
                  type="button"
                >
                  Whitepaper
                </button>
              </Link> */}
              <a href="https://homeqube.gitbook.io/homeqube/" target="_blank" rel="noopener noreferrer">
                <button class="new-black-button btn btn-lg rounded-pill" type="button">
                  How to Docs
                </button>
              </a>
            </span>

            {/* <span class="nav-item text-center  mx-3">
              {!walletAddress && renderNotConnectedContainer()}
            </span> */}

            <ClickablePopover />
          </div>
          <div className="new-nav-responsive-btn">
            <ClickablePopover />
            <button onClick={showNavbar} className="new-nav-btn">
              <FaBars />
            </button>
          </div>

        </div>
      </header>
    </>
  );
}

export default NewNavbar;
