import React from "react";
import twitter from "../../images/Icons/twitter.svg";
// import architect from "../../images/Blog/architect.jpg"
import house from "../../images/Blog/house.jpg"
import robot from "../../images/Blog/robot.jpg"
import blog1 from "../../images/NewImages/newBlog/blog1.svg"

function IsHomeBuildingRipeForARevolutionary() {
  return (
    <>
      <section class="benefits-container text-dark text-sm-start pb-5">
        <div class="col link d-flex align-items justify-content">
          <div class="container p-3">
            <div class="carbon-content-container-bg">
              <div class="d-sm-flex align-items-center justify-content-between">
                <div class="container-sm">
                  <h3 class="carbon-benefits-content">
                    Is homebuilding ripe for a revolutionary overhaul?
                  </h3>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/Is_homebuilding_ripe_for_a_revolutionary_overhaul.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img
                        class="blog-icon me-3"
                        src={twitter}
                        alt=""
                      />
                    </a>
                  </p>

                  <p class="carbon-text ">
                    <br />
                    <br />
                    The current homebuilding industry, facing supply chain
                    delays, inefficiencies, and sustainability issues, has never
                    been more exposed to a wave of transformations than in
                    today’s fast-changing, tech-oriented world.
                  </p>

                  <img
                    class="blog-image"
                    src={blog1}
                    alt=""
                  />

                  <p class="carbon-text ">
                    <br />
                    <br />
                    In the United States and elsewhere in the world, global
                    supply chain delays continue to plague the homebuilding
                    industry.
                    <br />
                    <br />
                    Housing market research firm Zonda <a
                      class="blog-link"
                      target="_blank"
                      href="https://www.wsj.com/articles/supply-chain-issues-leave-new-homes-without-garage-doors-and-gutters-11641724201?adobe_mc=MCMID%3D89524844545722503170667616297864135104%7CMCORGID%3DCB68E4BA55144CAA0A4C98A5%2540AdobeOrg%7CTS%3D1648476764"
                      rel="noreferrer"
                    > highlights
                    </a> that 90% of American builders surveyed point to supply
                    issues impacting their business, with factors like surging
                    demand for new housing as well as factory and transportation
                    hub closures due to COVID-19 coming together to cause home
                    construction delays and material shortages. <br />
                    <br />
                    What to expect from these problems but delayed openings and
                    prices on new construction getting driven up? Back in
                    November 2021, the average cost of a new US home <a
                      href="https://www.mansionglobal.com/articles/home-builders-are-turning-to-natural-materials-to-get-around-supply-chain-problems-01648206188"
                      class="blog-link"
                      target="_blank"
                      rel="noreferrer"
                    > saw 
                    </a>
                    a 19% hike year over year, or a whopping $416,900.
                    Construction’s aggregate cost of construction also <a
                      href="https://www.timesunion.com/business/article/Why-force-majeure-has-become-the-new-17003208.php"
                      rel="noreferrer"
                      class="blog-link"
                      target="_blank"
                    > rose </a> by 21% year over year, noted the National Association of
                    Home Builders.
                    <br />
                    <br />
                    These trends appear to mirror what’s happening elsewhere.
                  </p>

                  <h3 class="carbon-benefits-content">
                    Traditional homebuilding under scrutiny
                  </h3>

                  <p class="carbon-text ">
                    Homebuilding faces common issues ranging from the lack of
                    knowledge transparency in the architecture, engineering, and
                    construction (AEC) space, along with existing inefficiencies
                    on skilled labor, on which the industry is heavily
                    dependent.
                    <br />
                    <br />
                    Dominant construction materials like concrete and steel lead
                    to high carbon emissions. Some 39% of CO2 levels worldwide,
                    for instance, are <a
                      href="https://www.forbes.com/sites/jeffsteele/2022/03/25/luxury-real-estate-takes-on-its-carbon-footprint/?sh=722a01f5e6e2"
                      class="blog-link"
                      rel="noreferrer"
                      target="_blank"
                    > generated </a> by the built environment and construction, according to the
                    World Green Building Council.
                    <br />
                    <br />
                    Despite the surge in e-commerce adoption, there’s no real
                    linkage between it and homebuilding.
                    <br />
                    <br />
                    Yet there are pockets of hope and progress across the
                    sector, as mediated by technology and innovations around
                    material use and processes.
                  </p>

                  <img class="blog-image" src={house} alt="" />

                  <br />
                  <br />

                  <h3 class="carbon-benefits-content">Prefab in focus</h3>

                  <p class="carbon-text ">
                    House construction is constantly evolving globally.
                    Prefabricated or prefab homes today have become an
                    attractive option for budding homeowners in their bid for a
                    cheaper, more sustainable home built in a shorter time.
                    <br />
                    <br />
                    In markets like the Philippines, prefab housing is <a
                      href="https://www.manilatimes.net/2022/03/08/business/real-estate-and-property/prefab-housing/1835468"
                      rel="noreferrer"
                      class="blog-link"
                      target="_blank"
                    > catching </a> on if the demand is any indicator. With house components
                    already made, builders simply assemble the pieces on-site
                    like a puzzle, cutting construction time short and
                    eliminating the need to reinforce or lay down hollow blocks
                    and apply cement.
                    <br />
                    <br />
                    Prefab houses are also built based on standards set by
                    existing laws. With countries like the Philippines, India,
                    and similar markets prone to natural disasters, they are
                    designed to withstand the strongest quakes or monsoon rains.
                  </p>

                  <h3 class="carbon-benefits-content">
                    The rise of automation
                  </h3>

                  <p class="carbon-text ">
                    Prefab materials and assemblies appear to be part of a
                    bigger phenomenon called industrialized construction (IC).
                    IC combines several technology advancements -- mechanizes
                    and automates -- and incorporates them into the construction
                    process to transform design and manufacturing.
                    <br />
                    <br />
                    Industrializing construction entails a number of goals:
                  </p>

                  <ul class="carbon-text ">
                    <li>Increase labor productivity</li>
                    <li>Substitute labor-intensive processes with machines</li>
                    <li>Fast-track the rate of construction</li>
                    <li>Commission new projects more rapidly</li>
                    <li>Slash costs</li>
                    <li>Enhance overall quality and sustainability</li>
                    <li>Enable affordable customizations </li>
                  </ul>

                  <p class="carbon-text">
                    It’s estimated that by 2035, the majority of buildings will
                    be built using IC as construction’s sophisticated design and
                    manufacturing’s expertise in mass production meet halfway.
                    <br />
                    <br />
                    There is a vast room to do this, as the construction sector
                    is among the least digitized today, racked with repetitive,
                    labor-intensive processes. A McKinsey study showed that
                    large initiatives usually extend 20% beyond the initial
                    completion date and go up to 80% over budget!
                  </p>

                  <img class="blog-image" src={robot} alt="" />

                  <br />
                  <br />
                  <h3 class="carbon-benefits-content">Homebuilding in web3</h3>

                  <p class="carbon-text ">
                    Another endlessly enticing prospect: designing and building
                    homes on web 3.0, where technologies such as cloud
                    computing, blockchain, and artificial intelligence (AI)
                    synergize to take the built environment at an unprecedented
                    industrial-revolutionary scale.
                    <br />
                    <br />
                    Web3 is the name <a
                      class="blog-link"
                      href="https://economictimes.indiatimes.com/news/how-to/what-is-web3-why-are-so-many-people-asking-about-it/articleshow/90445518.cms"
                      target="_blank"
                      rel="noreferrer"
                    > used </a> to refer to a new kind of internet service built using
                    decentralized blockchains, the shared ledger systems used by
                    cryptocurrencies like Bitcoin.
                    <br />
                    <br />
                    Proponent Packy McCormick defines it as “the internet owned
                    by the builders and users, orchestrated with tokens.”
                    <br />
                    <br />
                    The various forms of web3 materializing in the near future
                    -- including decentralized social networks, play-to-earn
                    (P2E) video games rewarding players with tokens, and NFT
                    platforms for buying and selling digital assets -- are
                    anticipated to transform homebuilding, upend its traditional
                    gatekeepers, and usher in a middleman-free digital economy.
                    <br />
                    <br />
                    Via novel technologies that augment, a mix of gamification
                    and time-tested AEC logics has also become a viable way to
                    DIY home design and building, effectively democratizing the
                    project.
                    <br />
                    <br />
                    Smart contracts are another <a
                      class="blog-link"
                      href="https://www.ibm.com/topics/smart-contracts"
                      target="_blank"
                      rel="noreferrer"
                    >part</a>  of the big picture. These are programs stored on a
                    blockchain to automate the execution of agreements, such
                    that participants (e.g., the contractor and homebuying
                    client) can be certain of expected outcomes; the transaction
                    cannot be changed and only parties given permission can see
                    the results.
                    <br />
                    <br />
                    Accuracy, trust and transparency, and security are just some
                    benefits of smart contracts.
                    <br />
                    <br />
                    Homebuilding globally is undergoing drastic changes and
                    transformations. For a number of insiders and new key
                    players, it’s long overdue and only fitting that its
                    revolution is underway.
                    <br />
                    <br />
                    <i>
                      
                      What are the problems besetting the current homebuilding
                      industry and where is the wave of new technologies and
                      movements taking it? <a
                        href="/documents/HomeQube Brief 1.0 [with Whitepaper].pdf"
                        class="blog-link"
                        target="_block"
                      > Read the Homeqube whitepaper </a> to learn more.
                    </i>
                    <br />
                    <br />
                    Published on March 2022
                  </p>

                  <p class="carbon-text-blog">
                    Share
                    <br />
                    <a
                      href="https://twitter.com/share?url=https://www.qube.homeqube.com/Blog/Is_homebuilding_ripe_for_a_revolutionary_overhaul.html"
                      target="_blank"
                      rel="noreferrer noopener pt-3"
                      class="flink-icon"
                    >
                      <img
                        class="blog-icon me-3"
                        src={twitter}
                        alt=""
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default IsHomeBuildingRipeForARevolutionary;
